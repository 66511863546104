const NOTIFICATION_SET = 'NOTIFICATION_SET';
const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

export const setNotification = message => dispatch => dispatch({type: NOTIFICATION_SET, message})
export const clearNotification = () => dispatch => dispatch({type: NOTIFICATION_CLEAR})

export const notificationActionTypes = {
    NOTIFICATION_SET,
    NOTIFICATION_CLEAR
}

export const notificationActionCreators = {
    setNotification,
    clearNotification
}