import { axiosWithAuth } from '../utils/';

const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
const REGISTRATION_FAIL = 'REGISTRATION_FAIL';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';
const LOGOUT = 'LOGOUT';

const registerUser = (values, redirect) => dispatch => {
    dispatch({type: REGISTRATION_REQUEST});

    axiosWithAuth()
        .post('/auth/register', values)
        .then(res => {
            console.log ('register user', res.data)
            const { token } = res.data;
            const data = {
                id: res.data.id,
                username: res.data.username,
            }
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(data.id));
            dispatch({type: REGISTRATION_SUCCESS, payload: data });
            redirect();
        })
        .catch(err => dispatch({type: REGISTRATION_FAIL, payload: err.message}));
}

const authenticateUser = (values, redirect) => dispatch => {
    console.log('LOGGING IN')
    dispatch({type: LOGIN_REQUEST});
    axiosWithAuth()
        .post(`/auth/login`, values)
        .then(res => {
            const { token } = res.data;

            const data = {
                id: res.data.id,
                email: res.data.email,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                organization: res.data.organization,
                type: res.data.type,
                blog_categories: res.data.blog_categories
            };
            localStorage.setItem('token', token);
            localStorage.setItem('id', (data.id));
            localStorage.setItem('email', (data.email));
            localStorage.setItem('first_name', (data.first_name));
            localStorage.setItem('last_name', (data.last_name));
            localStorage.setItem('organization', (data.organization));
            localStorage.setItem('type', (data.type));
            localStorage.setItem('blog_categories', (data.blog_categories));

            dispatch({type: LOGIN_SUCCESS, payload: data});
            redirect();
        })
        .catch(err => {
            console.log(err)
            dispatch({type: LOGIN_FAIL, payload: err.message})
        })
}

export const logoutUser = () => dispatch => {
    // console.log('Logout action')
    dispatch({type: LOGOUT});
    axiosWithAuth()
        .get('/auth/logout')
        .then(res => {
            // console.log ('logout user', res.data)
        })
        .catch(err => console.log ('logout user', err));
}

export const authActionTypes = {
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT
}

export const authActionCreators = {
    registerUser,
    authenticateUser,
    logoutUser
}