import React from "react";

import zora_proposal_header from "../../../assets/zora-proposal-header-small.jpg";

const Milestones = (props) => {
	const { refr, data } = props;

	return (
		<div className="page-content" id="tasks" ref={refr}>
			<div className="header-image">
				<img src={zora_proposal_header} alt="Banner" />
			</div>
			<div className="dark-box">
				<h1>Project Milestones</h1>
				<h2>Keeping the project on track</h2>
			</div>
			<div className="white-box">
				<h3>KEY MILESTONES</h3>
				{data.milestones.paragraphs.length > 0 && (
					<>
						{data.milestones.paragraphs.map((paragraph, index) => {
							return <p key={index}>{paragraph}</p>;
						})}
					</>
				)}
				<p>
					<b>
						We have drafted the below schedule for this project and its relevant
						stages:
					</b>
				</p>
			</div>
			<div className="box">
				<div className="table">
					<div className="table-header">
						<div className="left-column">
							<h3>Phase</h3>
						</div>
						<div className="right-column">
							<h3>Due Date</h3>
						</div>
					</div>
					{data.milestones.items.length > 0 && (
						<>
							{data.milestones.items.map((item, index) => {
								return (
									<div className="table-row" key={index}>
										<div className="left-column">
											<h4>{item.name}</h4>
										</div>
										<div className="right-column">
											<h4>{item.due_date}</h4>
										</div>
									</div>
								);
							})}
						</>
					)}
				</div>
			</div>
			<div className="page-footer">
				<div className="line"></div>
				<p className="uppercase small-font">
					20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672
				</p>
			</div>
		</div>
	);
};

export default Milestones;
