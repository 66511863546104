import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Links = ({ open, setOpen }) => {
	const organization = localStorage.getItem("organization");

	const [sidebarLinks, setSidebarLinks] = useState([]);

	useEffect(() => {
		const links = [
			{
				title: "Overview",
				link: "/dashboard/overview",
			},
			{
				title: "Tableau",
				link: "/dashboard/tableau",
			},
			{
				title: "Blog",
				link: "/dashboard/blog",
			},
			{
				title: "Proposals",
				link: "/dashboard/proposals",
			}
		];

		switch (organization) {
			case "Zora Digital Admin":
				setSidebarLinks(links);
				break;
      case "Zora Digital":
        setSidebarLinks(links);
        break;
      case "Cupcakes & Cashmere":
        setSidebarLinks([links[0], links[1]]);
        break;
			default:
        setSidebarLinks([links[0]]);
				break;
		}
	}, [organization]);

	return (
		<nav className="links">
			{sidebarLinks.map((item, index) => (
				<NavLink className={({ isActive }) => isActive ?  'current' : null} key={index} to={item.link} onClick={() => setOpen(!open)}>
					{item.title}
				</NavLink>
			))}
		</nav>
	);
};

export default Links;
