import React from "react";

const EditorTasks = (props) => {
    const { data, handlePhaseChange, handleTaskChange, handleAddPhase, handleRemovePhase, handleAddTask, handleRemoveTask } = props

	return (
		<>
			{data.tasks.phases.map((task, index) => {
				return (
					<div className="editor-map" key={index}>
						<div className="label-container">
							<label className="editor-input-label">Phase {index + 1}:</label>
							<button className="remove-button darkred" onClick={(e) => handleRemovePhase(e, index)}>Remove</button>
						</div>
						<textarea
							className={`editor-input input-paragraph`}
							type="text"
							name={`paragraph-${index}`}
							value={task.paragraph}
							onChange={(e) =>
								handlePhaseChange(index, e)
							}
						></textarea>
						
						{task.items.map((item, item_index) => {
							return (
								<div className="editor-map" key={item_index}>
									<div className="label-container">
										<label className="editor-input-label">Task {item_index + 1}:</label>
										<button className="remove-button darkred" onClick={(e) => handleRemoveTask(index, item_index, e)}>Remove</button>
									</div>
									<textarea
										className={`editor-input input-description`}
										type="text"
										name={`item-${item_index}`}
										value={item}
										onChange={(e) =>
											handleTaskChange(index, item_index, e)
										}
									></textarea>
								</div>
							)
						})}
						<button className="add-button gold" onClick={(e) => handleAddTask(index, e)}>Add task for Phase {index + 1}</button>
					</div>
				);
			})}
			<button className="add-button phase"  onClick={(e) => handleAddPhase(e)}>Add Phase</button>
			{/* {data[key1][key2].map((item, index) => {
				return (
					<div className="editor-map" key={index}>
                        <div className="label-container">
						    <label className="editor-input-label">{name} {index + 1}:</label>
                            <button className="remove-button darkred" onClick={(e) => handleRemoveParagraph(key1, key2, index, e)}>Remove</button>
                        </div>
						<textarea
							className={`editor-input input-paragraph`}
							type="text"
							name={`${key1}-${key2}-${name}`}
							value={item}
							onChange={(e) =>
								handleMapChange(key1, key2, index, e)
							}
						></textarea>
					</div>
				);
			})}
			<button className="add-button" onClick={(e) => handleAddParagraph(key1, key2, e)}>Add {name}</button> */}
		</>
	);
};

export default EditorTasks;
