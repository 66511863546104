import React from "react";

import zora_proposal_header from "../../../assets/zora-proposal-header-small.jpg";

const Scope = (props) => {
	const { refr, data } = props;

	return (
		<div className="page-content" id="scope" ref={refr}>
			<div className="header-image">
				<img src={zora_proposal_header} alt="Banner" />
			</div>
			<div className="dark-box">
				<h1>Project Scope</h1>
				<h2>Exactly what this project will involve</h2>
			</div>
			<div className="box gold-titles">
				<h3>Project Scope</h3>
				{data.scope.paragraphs.length > 0 && (
					<>
						{data.scope.paragraphs.map((paragraph, index) => {
							return <p key={index}>{paragraph}</p>;
						})}
					</>
				)}
				<p>
					Below outlines the scope of {data.project_name}:
				</p>
				{data.scope.items.length > 0 && (
					<>
						{data.scope.items.map((item, index) => {
							return (
								<div className="item" key={index}>
									<p>
										<b>
											{index + 1}. {item.name}
										</b>
									</p>
									<p>{item.description}</p>
								</div>
							);
						})}
					</>
				)}
			</div>
			<div className="page-footer">
				<div className="line"></div>
				<p className="uppercase small-font">
					20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672
				</p>
			</div>
		</div>
	);
};

export default Scope;
