import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { clearNotification } from "../../actions/notificationActions";

function Notification (props) {
  let dispatch = useDispatch();
  let message = useSelector(state => state.notifications.message);

  if(!message)
    return null;
  return (
    <div style={{position: 'fixed', top: 30, right: 30, background: 'white', borderRadius: 5, padding: '10px 20px', width: 200, margin: 'auto', boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)'}}>
      <span onClick={() => dispatch(clearNotification())} style={{cursor: 'pointer', position: 'absolute', right: 10, top: 10}}>x</span>
      {message}
    </div>
  );
}

export default Notification;