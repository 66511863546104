import React from "react";
// import { convertDate } from "../../../utils";

import zora_proposal from "../../../assets/zora-proposal-intro.jpg";

const Introduction = (props) => {

	const {refr, data } = props;

	const dateString = (dateData) => {
		var date = new Date(dateData);
		var dateString = new Date(date.getTime()).toISOString().split("T")[0];
		let dsr = dateString.split("-")
		return `${dsr[1]}/${dsr[2]}/${dsr[0]}`
	}

	let submitted = dateString(data.submitted);

	return (
		<div className="page-content" id="introduction" ref={refr}>
            <div className="header-image">
				<img src={zora_proposal} alt="Banner" />
			</div>
			<div className="header-box">
				<h1>Project</h1>
				<h2>{data.project_name}</h2>
                <h3>{data.project_description}</h3>
			</div>
			<div className="box center">
				<p>Proposal for <b>{data.company}</b></p>
				<p>Prepared by <b>{data.prepared_by}</b></p>
				<p>Submitted <b>{submitted}</b></p>
			</div>
            <div style={{color: '#663293', textAlign: 'center'}}>
                <p>Thank you for the opportunity to propose this project.</p>
                <p>We look forward to exceeding your expectations.</p>
            </div>
		</div>
	);
};

export default Introduction;
