import axios from 'axios';
import { setNotification } from '../actions/notificationActions';
import { logoutUser } from '../actions/authActions';

const checkUnauthorized = (e, dispatch) => {

    if (e.response && e.response.status !== 403 && e.response.status !== 401)
        return;
    localStorage.clear();
    if (dispatch){
        dispatch(setNotification('Session has expired'));
        dispatch(logoutUser());
    } else {
        window.location.href = '/dashboard';
    }
}

export const axiosWithAuth = () => {
    const token = localStorage.getItem('token');
    let baseURL = 'https://cloud.zora.digital:8080/api';
    if (process.env.REACT_APP_DEVELOPMENT === 'true'){
        console.log('Development mode API');
        baseURL = 'http://localhost:8080/api';
    }
    return axios.create({
        baseURL: baseURL,
        withCredentials: true,
        headers: {
            Authorization: token,
        }
    })
}

export const getRequest = async (path, dispatch) => {
    try {
        let res = await axiosWithAuth().get(path);
        return [true, res.data];
    } catch (e) {
        checkUnauthorized(e, dispatch);
        return [false, e.response.data.message];
    }
}

export const postRequest = async (path, dispatch, data = null) => {
    try {
        let res = await axiosWithAuth().post(path, data);
        return [true, res];
    } catch (e) {
        checkUnauthorized(e, dispatch);
        return [false, e.response.data.message];
    }
}

export const putRequest = async (path, dispatch, data = null) => {
    try {
        let res = await axiosWithAuth().put(path, data);
        return [true, res];
    } catch (e) {
        checkUnauthorized(e, dispatch);
        return [false, e.response.data.message];
    }
}

export const deleteRequest = async (path, dispatch, data = null) => {
    try{
        let res = await axiosWithAuth().delete(path, data);
        return [true, res];
    } catch (e) {
        checkUnauthorized(e, dispatch);
        return [false, e.response.data.message];
    }
    
}
