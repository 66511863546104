import React from 'react';
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { useSelector } from 'react-redux';
import Login from './components/Login/Login.js';
import Dashboard from './components/Dashboard/Dashboard.js';
import ProposalViewer from './components/ProposalViewer/ProposalViewer.js';

function App(props) {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path='/dashboard/*' element={isAuthenticated ? <Dashboard /> : <Navigate to='/' />} />
        <Route exact path="/proposal" element={<ProposalViewer />} />
        <Route path="*" element={<Navigate replace to='/dashboard/overview' />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
