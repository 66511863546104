import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomToolbar = () => {
    let colorStyle = {
        opacity: 0,
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        display: 'inline-block',
        float: 'left',
        height: 0,
        padding: '0px 0px',
        width: 0
    };
    return (<div id="toolbar">
      <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
        <option value="1" />
        <option value="2" />
        <option value="3" />
        <option value="4" />
        <option selected />
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className='ql-colorPicker' id='color-picker-placeholder' style={{background: 'black', height: 20, width: 20, marginTop: 2}}onClick={e => e.preventDefault()}>
      </button>
      <input type='color' id='color-picker' className='ql-colorPicker' style={colorStyle}/>
      <button className="ql-blockquote" />
      <button className="ql-list" value='ordered'/>
      <button className="ql-list" value='bullet' />
      <button className="ql-indent" value='+1' />
      <button className="ql-indent" value='-1' />
      <button className="ql-link"/>
      <button className="ql-image"/>
      <span class="ql-formats">
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
        <button class="ql-align" value="justify"></button>
      </span>
    </div>);
}
  

class Editor extends React.Component {

    modules = {
        toolbar: {
          container: "#toolbar",
          handlers: {
              'colorPicker': function ()  { 
                    let picker = document.getElementById('color-picker');  
                    picker.addEventListener('change', () => {
                        console.log(this.quill); 
                        this.quill.format('color', picker.value)
                        let placeholder = document.getElementById('color-picker-placeholder');  
                        placeholder.style.background = picker.value;
                    }, false);
                    picker.focus();
                    picker.click();
                    picker.select();
              }
            }
        },
        clipboard: {
            matchVisual: false
        }
    }
    render() {
        return (<>
            <CustomToolbar />
            <ReactQuill theme="snow" modules={this.modules} value={this.props.content} onChange={this.props.setContent}/>
          </>);
    }
}


export default Editor;