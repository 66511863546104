import { axiosWithAuth } from "../utils/";

const PROPOSAL_START = "PROPOSAL_START";
const PROPOSAL_SUCCESS = "PROPOSAL_SUCCESS";
const PROPOSAL_FAIL = "PROPOSAL_FAIL";

const PROPOSALS_LIST_START = "PROPOSALS_LIST_START";
const PROPOSALS_LIST_SUCCESS = "PROPOSALS_LIST_SUCCESS";
const PROPOSALS_LIST_FAIL = "PROPOSALS_LIST_FAIL";

const PROPOSALS_CREATE_START = "PROPOSALS_CREATE_START";
const PROPOSALS_CREATE_SUCCESS = "PROPOSALS_CREATE_SUCCESS";
const PROPOSALS_CREATE_FAIL = "PROPOSALS_CREATE_FAIL";

const PROPOSALS_SAVE_START = "PROPOSALS_SAVE_START";
const PROPOSALS_SAVE_SUCCESS = "PROPOSALS_SAVE_SUCCESS";
const PROPOSALS_SAVE_FAIL = "PROPOSALS_SAVE_FAIL";

const PROPOSALS_DELETE_START = "PROPOSALS_DELETE_START";
const PROPOSALS_DELETE_SUCCESS = "PROPOSALS_DELETE_SUCCESS";
const PROPOSALS_DELETE_FAIL = "PROPOSALS_DELETE_FAIL";

const requestProposal = (id) => (dispatch) => {
	
	dispatch({ type: PROPOSAL_START });

	axiosWithAuth()
		.get(`/proposals/public/${id}`)
		.then((res) => {
			dispatch({ type: PROPOSAL_SUCCESS, payload: res.data });
		})
		.catch((err) => dispatch({ type: PROPOSAL_FAIL, payload: err.message }));
};

const getProposals = (id) => (dispatch) => {
	dispatch({ type: PROPOSALS_LIST_START });

	axiosWithAuth()
		.get(`/proposals/list/${id}`)
		.then((res) => {
			// console.log('proposal list', res.data)
			dispatch({ type: PROPOSALS_LIST_SUCCESS, payload: res.data });
		})
		.catch((err) =>
			dispatch({ type: PROPOSALS_LIST_FAIL, payload: err.message })
		);
};

const createProposal = (values, props) => (dispatch) => {
	dispatch({ type: PROPOSALS_CREATE_START });

	axiosWithAuth()
		.post(`/proposals/`, values)
		.then((res) => {
			// console.log('proposal created', res.data)
			dispatch({ type: PROPOSALS_CREATE_SUCCESS, payload: res.data });
			window.location.href = `/dashboard/proposaleditor?id=${res.data.id}`;
		})
		.catch((err) =>
			dispatch({ type: PROPOSALS_CREATE_FAIL, payload: err.message })
		);
};

const saveProposal = (values) => (dispatch) => {
	dispatch({ type: PROPOSALS_SAVE_START });

	axiosWithAuth()
		.put(`/proposals/${values.id}`, values)
		.then((res) => {
			dispatch({ type: PROPOSALS_SAVE_SUCCESS, payload: res.data });
		})
		.catch((err) =>
			dispatch({ type: PROPOSALS_SAVE_FAIL, payload: err.message })
		);
};

const deleteProposal = (id, proposal_id) => (dispatch) => {
	dispatch({ type: PROPOSALS_DELETE_START });
	const values = {
		proposal_id: proposal_id,
	};
	axiosWithAuth()
		.delete(`/proposals/${id}`, { data: values })
		.then((res) => {
			dispatch({ type: PROPOSALS_DELETE_SUCCESS, payload: res.data });
		})
		.then(() => {
			dispatch({ type: PROPOSALS_LIST_START });

			axiosWithAuth()
				.get(`/proposals/list/${id}`)
				.then((res) => {
					// console.log('proposal list', res.data)
					dispatch({ type: PROPOSALS_LIST_SUCCESS, payload: res.data });
				})
				.catch((err) =>
					dispatch({ type: PROPOSALS_LIST_FAIL, payload: err.message })
				);
		})
		.catch((err) =>
			dispatch({ type: PROPOSALS_DELETE_FAIL, payload: err.message })
		);
};

const saveSignature = (id, key, values) => (dispatch) => {
	dispatch({ type: PROPOSALS_SAVE_START });
	let changes = { 
		...values,
		key: key
	}
	axiosWithAuth()
		.put(`/proposals/public/${id}`, changes)
		.then((res) => {
			dispatch({ type: PROPOSALS_SAVE_SUCCESS, payload: res.data });
		})
		.then(() => {
			dispatch({ type: PROPOSAL_START });

			axiosWithAuth()
				.get(`/proposals/public/${id}`)
				.then((res) => {
					dispatch({ type: PROPOSAL_SUCCESS, payload: res.data });
				})
				.catch((err) => dispatch({ type: PROPOSAL_FAIL, payload: err.message }));
		})
		.catch((err) =>
			dispatch({ type: PROPOSALS_SAVE_FAIL, payload: err.message })
		);
};

export const proposalActionTypes = {
	PROPOSAL_START,
	PROPOSAL_SUCCESS,
	PROPOSAL_FAIL,
	PROPOSALS_LIST_START,
	PROPOSALS_LIST_SUCCESS,
	PROPOSALS_LIST_FAIL,
	PROPOSALS_CREATE_START,
	PROPOSALS_CREATE_SUCCESS,
	PROPOSALS_CREATE_FAIL,
	PROPOSALS_SAVE_START,
	PROPOSALS_SAVE_SUCCESS,
	PROPOSALS_SAVE_FAIL,
	PROPOSALS_DELETE_START,
	PROPOSALS_DELETE_SUCCESS,
	PROPOSALS_DELETE_FAIL,
};

export const proposalActionCreators = {
	requestProposal,
	getProposals,
	createProposal,
	saveProposal,
	deleteProposal,
	saveSignature
};
