import React from 'react';
import { Link } from 'react-router-dom';
import { getRequest } from '../../utils';
import { useDispatch } from 'react-redux';




class BlogManager extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        blogs: [],
        isLoaded: false
      }
    }

    async componentDidMount() {
      let organization = localStorage.getItem('organization');
      let [success, blogs] = await getRequest(`/blogs/public/organization/${organization}`, this.props.dispatch);
      if (success)
        this.setState({ isLoaded: true, blogs });
      else 
        this.setState({ isLoaded: true, blogs: [], error: 'Could not find blog posts.' });
    }

    render() {
      let organization = localStorage.getItem('organization');
    
      if (!this.state.isLoaded)
        return 'Loading...';

    return (
        <div style={{paddingTop: 50, height: '100%'}}>
          <div style={{textAlign: 'center'}}>
            <h3 style={{display: 'inline'}}>Manage Blogs</h3>
            <button style={{float: 'right'}}><a href='blog/new'>New Blog</a></button>
          </div>
          <div id='blog-manager'>
              {this.state.blogs.length ? this.state.blogs.map(blog => 
              <Link key={blog.id} to={`edit/${blog.id}`} style={{position: 'relative', background: 'white', color: 'black', margin: 20, padding: 10, paddingTop: 20, paddingBottom: 40, borderRadius: 5, textAlign: 'center', border: '1px solid #bbbbbb', boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)'}} state={{blog}}>
                <img alt='blog thumbnail' src={`https://zora-blog.s3.us-east-2.amazonaws.com/${organization}/${blog.id}.png`} style={{width: 200, height: 200, objectFit: 'cover', borderRadius: 2}} />
                <div>{blog.title}</div>
                <p style={{fontSize: '.75em', position: 'absolute', bottom: 5, width: '100%', }}>Last Updated: {blog.date.slice(0, 10)}</p>
              </Link>
            ) : <p>Could not find any existing blogs...</p>}
          </div>
        </div>
    )
  }
}

const BlogManagerComponent = (props) => {let dispatch = useDispatch(); return <BlogManager {...props} dispatch={dispatch} /> }
export default BlogManagerComponent;