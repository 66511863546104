import React from 'react';

import overview_welcome from '../../assets/welcome-image.png';


const Overview = () => {
    return (
        <div className="overview">
            <div className="welcome-message">
                <img src={overview_welcome} alt="Welcome" />
                <h1>Welcome to the Zora Portal</h1>
            </div>
        </div>
    )
}

export default Overview;