import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActionCreators } from '../../actions';
import { useLocation } from 'react-router';

import login_building from '../../assets/login-building.jpg';
import zora_logo from '../../assets/zora-logo-color.svg';
import username_icon from '../../assets/username-icon.svg';
import password_icon from '../../assets/password-icon.svg';

const Login = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const {isLoading, error} = useSelector(
        state => ({
            isLoading: state.authentication.isLoading,
            error: state.authentication.error
        })
    
    )

    const authenticateUser = authActionCreators.authenticateUser

    const [login, setLogin] = useState({
        email: "",
        password: "",
    })

    const [username, setUsername] = useState("Username");

    const focusClick = (e, field) => {
        e.preventDefault()
        document.getElementById(field).focus()
    }

    const handleChange = (event) => {
        event.preventDefault()
        setLogin({
            ...login,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(authenticateUser(login, () => {window.location.href = '/dashboard/overview'}))
    }

    useEffect(() => {
        if (location.pathname === "/user"){
            setUsername("Zora Team Login")
        }
    }, [location])

    return (
        <div className="login">
            <div className="login__container">
                <div className="login__image">
                    <img src={login_building} alt="Cook County Building" />
                </div>
                <form className="login__form" method='dialog' onSubmit={(e) => handleSubmit(e)}>
                    <img src={zora_logo} alt="Zora Digital Logo" />
                    <h1>
                        Welcome to the Zora Portal, please login:
                    </h1>
                    <div className="login__container__items" onClick={(e) => focusClick(e, "email")}>
                        <img src={username_icon} alt="Username" />
                        <input className="login__container__items__input" id="email" type="email" name="email" value={login.email} onChange={handleChange} placeholder={username} required>
                        </input>
                    </div>
                    <div className="login__container__items" onClick={(e) => focusClick(e, "password")}>
                        <img src={password_icon} alt="Password" />
                        <input className="login__container__items__input" id="password" type="password" name="password" value={login.password} onChange={handleChange} placeholder="Password" required>
                        </input>
                    </div>
                    {isLoading ? (
                            <div className="loading"></div>
                        ) : (
                            <button className="login__container__items" id="submit-button" type="submit">Login</button>
                    )}
                    {error && (
                        <div className="error">Unable to login, please check your password.<br/>{error}</div>
                    )}
                    <div className="login__container__forgotText">
                        <a className="login__container__forgotText__forgotLinks" href="/">Forgot Username/Password?</a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;