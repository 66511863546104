import React, { useState } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';

import Sidebar from "../Sidebar/Sidebar.js";
import Navbar from "../Navbar/Navbar.js";
import Overview from '../Overview/Overview.js';
import Tableau from '../Tableau/Tableau.js';
import Blog  from '../Blog/Blog.js';
import BlogManager from '../Blog/BlogManager';
import Proposals from '../Proposals/Proposals.js';
import ProposalEditor from '../Proposals/ProposalEditor.js';
import Notification from '../Notification/Notification.js';

const Dashboard = (props) => {
  const location = useLocation();
  const [tableauLoad, setTableauLoad] = useState(false)

  if(location.pathname !== "/dashboard/tableau" && tableauLoad){
    console.log('not tableau')
    setTableauLoad(false) //TODO: What is this?
  }
  console.log(location);
  return (
    <div className="main-window">
      <Sidebar {...props} />
      <Notification />
      <div className="main-container">
        <Navbar {...props} />
          <Routes>
            <Route path="/overview" element={<Overview />} />
            <Route path="/tableau" element={<Tableau {...props} tableauLoad={tableauLoad} setTableauLoad={setTableauLoad} />} />
            <Route path="/blog/new" element={<Blog {...props} />} />
            <Route path="/blog/edit/:id" element={<Blog {...props} />} />
            <Route path="/blog" element={<BlogManager {...props} />} />
            <Route path="/proposals" element={<Proposals {...props} />} />
            <Route path="/proposaleditor" element={<ProposalEditor {...props} />} />
            <Route element={<h1 className="coming-soon">Coming soon...</h1>} />
          </Routes>
      </div>
    </div>
  );
};

export default Dashboard;