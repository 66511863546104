import React from "react";

import zora_proposal_header from "../../../assets/zora-proposal-header-small.jpg";

const Tasks = (props) => {
	const { refr, data } = props;

	return (
		<div className="page-content" id="tasks" ref={refr}>
			<div className="header-image">
				<img src={zora_proposal_header} alt="Banner" />
			</div>
			<div className="dark-box">
				<h1>Project Tasks</h1>
				<h2>Exactly what this project will involve</h2>
			</div>
			<div className="white-box">
				<h3>Project Tasks</h3>
				{data.tasks.phases.length > 0 && (
					<>
						{data.tasks.phases.map((task, index) => {
							return (
								<div key={index}>
									<h4><b>PHASE {index + 1}: </b>{task.paragraph}</h4>
									{task.items.length > 0 && (
										<>
											{task.items.map((item, item_index) => {
												return <p key={item_index}><b>Task {item_index + 1}: </b>{item}</p>;
											})}
										</>
									)}
								</div>
							);
						})}
					</>
				)}
				{/* {data.tasks.paragraphs.length > 0 && (
					<>
						{data.tasks.paragraphs.map((paragraph, index) => {
							return <p key={index}>{paragraph}</p>;
						})}
					</>
				)}
				<p>
					The tasks required to achieve the scope as described previous are as
					follows:
				</p>
				{data.tasks.items.length > 0 && (
					<>
						{data.tasks.items.map((item, index) => {
							return (
								<div className="item" key={index}>
									<p>
										<b>TASK {index + 1}:</b> {item.name}
									</p>
									<p>{item.description}</p>
								</div>
							);
						})}
					</>
				)} */}
			</div>
			{/* <div className="box center">
				<p>
					The scope of this project are limited to the above listed items and
					tasks.
					<br />
					Any additional items will be counted as out of scope, and may only be{" "}
					<br />
					added with separate authorization and approval from [CLIENT].
				</p>
				<p>Extra items may only be added through the use of separate SOWs.</p>
			</div> */}
			<div className="page-footer">
				<div className="line"></div>
				<p className="uppercase small-font">
					20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672
				</p>
			</div>
		</div>
	);
};

export default Tasks;
