export function convertDate(date, format) {
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  if (month < 10 ) {
      month = `0${month}`
    }
  if (day < 10) {
    day = `0${day}`
  }
  if (format === "yyyy-MM-dd"){
    return `${year}-${month}-${day}`;  
  } else {
    return `${month}/${day}/${year}`;
  }
}