import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { proposalActionCreators } from "../../actions";

import ProposalCard from "./ProposalCard.js";

const Proposals = (props) => {
	const dispatch = useDispatch();
	const master_list = useSelector((state) => state.proposal.list);
	const { isLoading, error } = useSelector((state) => ({
		isLoading: state.proposal.isLoading,
		error: state.proposal.error,
	}));

	const { getProposals, createProposal, deleteProposal } = proposalActionCreators;

	const [list, setList] = useState(master_list);

	const [search, setSearch] = useState({
		for: "",
		by: "project_name",
	});

	const handleCreate = () => {
        const id = localStorage.getItem('id')
		const first_name = localStorage.getItem('first_name')
		const last_name = localStorage.getItem('last_name')
        const newProposal = {
            user_id: id,
            modified_by: id,
			prepared_by: `${first_name} ${last_name}`,
            client: "New Client",
            company: "New Company",
            project_name: "New Project",
            project_description: "Project Description"
        }
        // console.log(newProposal)
        dispatch(createProposal(newProposal, props))

    };

	const handleCopy = (e, proposal) => {
		e.preventDefault();
        const id = localStorage.getItem('id')
		const first_name = localStorage.getItem('first_name')
		const last_name = localStorage.getItem('last_name')
        const newProposal = {
            user_id: id,
            modified_by: id,
			prepared_by: `${first_name} ${last_name}`,
			client: proposal.client,
            company: proposal.company,
            project_name: proposal.project_name,
            project_description: proposal.project_description,
			overview: proposal.overview,
			objectives: proposal.objectives,
			scope: proposal.scope,
			tasks: proposal.tasks,
			deliverables: proposal.deliverables,
			components: proposal.components,
			assumptions: proposal.assumptions,
			milestones: proposal.milestones,
			costs: proposal.costs,
        }
        dispatch(createProposal(newProposal, props))
    };

	const handleChange = (event) => {
		event.preventDefault();
		setSearch({
			...search,
			[event.target.name]: event.target.value,
		});
	};

	const handleSearch = (e) => {
		e.preventDefault();
		const filtered = master_list.filter((proposal) => {
			return proposal[search.by]
				.toLowerCase()
				.includes(search.for.toLowerCase());
		});
		setList(filtered);
	};

	const handleClear = (e) => {
		e.preventDefault();
		setSearch({ ...search, for: "" });
		setList(master_list);
	};

    const handleDelete = async (proposal_id, e) => {
		e.preventDefault();
		console.log("deleting...", proposal_id);
        const id = localStorage.getItem("id");
		dispatch(deleteProposal(id, proposal_id))
	};

    const checked = (e) => {
        if (search.by === "project_name"){
            return true
        }else {
            return false
        }
    }

	useEffect(() => {
		setList(master_list);
	}, [master_list]);

	useEffect(() => {
		const id = localStorage.getItem("id");
		dispatch(getProposals(id));
	}, [dispatch, getProposals]);

	return (
		<div className="proposals">
			{isLoading ? (
				<div className="loading-container">
					<div className="loading"></div>
				</div>
			) : (
				<>
					<div className="proposals-buttons">
						<form className="search-form" onSubmit={(e) => handleSearch(e)}>
							<div className='row'>
							<input
                                className="search-box"
								type="search"
								id="for"
								name="for"
								value={search.for}
								onChange={(e) => handleChange(e)}
								placeholder="Search..."
							></input>
								<input
									type="radio"
									id="searchChoice1"
									name="by"
									value="project_name"
                                    onChange={(e) => handleChange(e)}
                                    checked={checked()}
								/>
								<label htmlFor="searchChoice1">Project Name</label>

								<input
									type="radio"
									id="searchChoice2"
									name="by"
									value="company"
                                    onChange={(e) => handleChange(e)}
								/>
								<label htmlFor="searchChoice2">Company</label>

								<input
									type="radio"
									id="searchChoice3"
									name="by"
									value="client"
                                    onChange={(e) => handleChange(e)}
								/>
								<label htmlFor="searchChoice3">Client</label>
							</div>
							<div className='row'>
								<button type="submit">Search</button>
								<button onClick={handleClear}>Clear</button>
								<button className="create-new" onClick={handleCreate}>Create New Proposal</button>
							</div>
						</form>
					</div>
					<div className="proposals-list">
						{list.map((proposal, index) => {
							return (
								<ProposalCard
									proposal={proposal}
                                    handleDelete={handleDelete}
									handleCopy={handleCopy}
									key={index}
									index={index}
									{...props}
								/>
							);
						})}
					</div>
				</>
			)}
			{error && (
				<div className="error">
					Error loading proposals...
					<br />
					{error}
				</div>
			)}
		</div>
	);
};

export default Proposals;
