import React from "react";

import zora_proposal_header from "../../../assets/zora-proposal-header-small.jpg";

const Brief = (props) => {
	const { refr, data } = props;

	return (
		<div className="page-content" id="brief" ref={refr}>
			<div className="header-image">
				<img src={zora_proposal_header} alt="Banner" />
			</div>
			<div className="dark-box">
				<h1>Brief & Objectives</h1>
				<h2>What we are setting out to achieve</h2>
			</div>
			<div className="box gold-titles">
				<h3>Project Overview</h3>
				{data.overview.paragraphs.length > 0 &&
					<>
						{data.overview.paragraphs.map((paragraph, index) => {
							return <p key={index}>{paragraph}</p>;
						})}
					</>
				}
			</div>
			<div className="box gold-titles">
				<h3>Project Objectives</h3>
				{data.objectives.items.length > 0 &&
					<>
						{data.objectives.items.map((item, index) => {
							return (
								<div className="item" key={index}>
									<p>
										<b>{item.name}:</b> {item.description}
									</p>
								</div>
							);
						})}
					</>
				}
			</div>
			<div className="page-footer">
				<div className="line"></div>
				<p className="uppercase small-font">
					20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672
				</p>
			</div>
		</div>
	);
};

export default Brief;
