import React from "react";
import Popup from "reactjs-popup";

const DeleteModal = (props) => {
	const { open, closeModal, handleDelete, proposal } = props;
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="modal">
				<button className="close" onClick={closeModal}>
					&times;
				</button>
				<p>
					Are you sure you want to delete <b>{proposal.project_name}</b>?
				</p>
				<div className="delete-buttons">
					<button onClick={(e) => closeModal()}>Cancel</button>
					<button
						className="darkred"
						onClick={(e) => handleDelete(proposal.id, e)}
					>
						Delete
					</button>
				</div>
			</div>
		</Popup>
	);
};

export default DeleteModal;
