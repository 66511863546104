import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { authActionCreators } from '../../actions';
import { Link } from "react-router-dom";
import Links from "../Links/Links.js";
import Burger from "./Burger/index";

import default_icon from '../../assets/avatar.svg'

const Navbar = (props) => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const logoutUser = authActionCreators.logoutUser

  const first_name = localStorage.getItem('first_name')
  const last_name = localStorage.getItem('last_name')
  // const email = localStorage.getItem('email')
  const organization = localStorage.getItem('organization')
  var userIcon = default_icon

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logoutUser())
  };

  return (
    <>
      <div className="dropdown-wrap">
        <div className="menu">
          <Burger open={open} setOpen={setOpen} />
        </div>
        <div className="title">
          {/* {first_name ? first_name : "Error, please login again."} */}
        </div>
      </div>
      <div className={`dropdown ${open ? "active" : ""}`}>
        <div className="sidebar-info">
          <div className="user--image">
            <img src={userIcon} alt="Profile"/>
          </div>
          <div className="user--info">
            <div className="name">{`${first_name} ${last_name}`}</div>
            {/* <div className="email">{`${email}`}</div> */}
            <div className="organization">{`${organization}`}</div>
          </div>
        </div>
        <Links {...props} open={open} setOpen={setOpen} />
        <nav className="links">
            <Link to="/" onClick={handleLogout}>
              <div className={`links__link`}>Logout</div>
            </Link>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
