import React from "react";

const EditorBlock = (props) => {
    const { data, key1, key2, name, handleMapChange, handleAddParagraph, handleRemoveParagraph } = props
	return (
		<>
			{data[key1][key2].map((item, index) => {
				return (
					<div className="editor-map" key={index}>
                        <div className="label-container">
						    <label className="editor-input-label">{name} {index + 1}:</label>
                            <button className="remove-button darkred" onClick={(e) => handleRemoveParagraph(key1, key2, index, e)}>Remove</button>
                        </div>
						<textarea
							className={`editor-input input-paragraph`}
							type="text"
							name={`${key1}-${key2}-${name}`}
							value={item}
							onChange={(e) =>
								handleMapChange(key1, key2, index, e)
							}
						></textarea>
					</div>
				);
			})}
			<button className="add-button" onClick={(e) => handleAddParagraph(key1, key2, e)}>Add {name}</button>
		</>
	);
};

export default EditorBlock;
