import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { proposalActionCreators } from '../../actions';
import { useLocation } from 'react-router-dom';

import Introduction from "./Pages/Introduction.js";
import Brief from "./Pages/Brief.js";
import Scope from "./Pages/Scope.js";
import Tasks from "./Pages/Tasks.js";
import Deliverables from "./Pages/Deliverables.js";
// import Components from "./Pages/Components.js";
import Assumptions from "./Pages/Assumptions.js";
import Milestones from "./Pages/Milestones.js";
import Costs from "./Pages/Costs.js";
import Signatures from "./Pages/Signatures.js";
import ProposalPrinter from './ProposalPrinter';

// import PDF from "../ProposalPDF/PDF.js";
// import PDFview from "../ProposalPDF/PDFview.js";

import logo from "../../assets/zora-digital-logo.png";

const getDimensions = (e) => {
	const { height } = e.getBoundingClientRect();
	const offsetTop = e.offsetTop;
	const offsetBottom = offsetTop + height;

	return {
		height,
		offsetTop,
		offsetBottom,
	};
};

const scrollTo = (e) => {
	e.scrollIntoView({
		behavior: "smooth",
		block: "start",
	});
};

const ProposalViewer = (props) => {
	let location = useLocation();

	const dispatch = useDispatch();
	const data = useSelector(state => state.proposal.data);
    const {isLoading, error} = useSelector(
        state => ({
            isLoading: state.proposal.isLoading,
            error: state.proposal.error
        })
    );

    const requestProposal = proposalActionCreators.requestProposal;

	const [visibleSection, setVisibleSection] = useState();

	const introRef = useRef(null);
	const briefRef = useRef(null);
	const scopeRef = useRef(null);
	const tasksRef = useRef(null);
    const deliverablesRef = useRef(null);
    // const componentsRef = useRef(null);
    const assumptionsRef = useRef(null);
    const milestonesRef = useRef(null);
	const costsRef = useRef(null);
	const signaturesRef = useRef(null);

	// const [generatePDF, setGeneratePDF] = useState(false)

    // const onClickGenerate = () => {
    //     setGeneratePDF(true)
    // }

	useEffect(() => {

        const sectionRefs = [
			{ section: "introduction", ref: introRef },
			{ section: "brief", ref: briefRef },
			{ section: "scope", ref: scopeRef },
			{ section: "tasks", ref: tasksRef },
            { section: "deliverables", ref: deliverablesRef },
            // { section: "components", ref: componentsRef },
            { section: "assumptions", ref: assumptionsRef },
            { section: "milestones", ref: milestonesRef },
			{ section: "costs", ref: costsRef },
			{ section: "signatures", ref: signaturesRef },
		];
        const pages = document.getElementById("pages")

		const handleScroll = () => {
			const scrollPosition = pages.scrollTop + 40;
			const selected = sectionRefs.find(({ section, ref }) => {
				const ele = ref.current;
				if (ele) {
					const { offsetBottom, offsetTop } = getDimensions(ele);
					return scrollPosition > offsetTop && scrollPosition < offsetBottom;
				} else {
					return null;
				}
			});
			if (selected && selected.section !== visibleSection) {
				setVisibleSection(selected.section);
			} else if (!selected && visibleSection) {
				setVisibleSection(undefined);
			}
		};

		handleScroll();
        
		pages.addEventListener("scroll", handleScroll);
		return () => {
			pages.removeEventListener("scroll", handleScroll);
		};
	}, [visibleSection, data]);

	useEffect(() => {
		if (location.search && location.search.slice(1, 3) === "id"){
			const id = location.search.slice(4)
			dispatch(requestProposal(id))
		}
	}, [location, dispatch, requestProposal])


	return (
		<div className="proposal-main" id="main">
			{data === null ?
				<div className="no-id" id="pages">
					<div className="loading-container">
						<div className="loading"></div>
					</div>
					{error && <p>{error}</p>}
				</div> :
				<>
				<div className='sidebar-filler' />
				<div className="sidebar">
					<div className="logo">
						<img src={logo} alt="Logo" />
					</div>
					<div className="links">
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "introduction" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(introRef.current);
							}}
						>
							Introduction
						</div>
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "brief" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(briefRef.current);
							}}
						>
							Brief & Objectives
						</div>
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "scope" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(scopeRef.current);
							}}
						>
							Scope
						</div>
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "tasks" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(tasksRef.current);
							}}
						>
							Tasks
						</div>
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "milestones" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(milestonesRef.current);
							}}
						>
							Milestones
						</div>
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "deliverables" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(deliverablesRef.current);
							}}
						>
							Deliverables
						</div>
						{/* <div
							type="button"
							className={`nav-link ${
								visibleSection === "components" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(componentsRef.current);
							}}
						>
							Components
						</div> */}
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "assumptions" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(assumptionsRef.current);
							}}
						>
							Assumptions
						</div>
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "costs" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(costsRef.current);
							}}
						>
							Costs
						</div>
						<div
							type="button"
							className={`nav-link ${
								visibleSection === "signatures" ? "selected" : ""
							}`}
							onClick={() => {
								scrollTo(signaturesRef.current);
							}}
						>
							Signatures
						</div>
						{/* <div
							type="button"
							className={`nav-link ${
								visibleSection === "components" ? "selected" : ""
							}`}
							onClick={(e) => onClickGenerate()}
						>
							{generatePDF ? (<PDFview data={data}/>):(<>Download PDF</>)}
						</div> */}
						{isLoading ? '' : <ProposalPrinter data={data} /> }
					</div>
				</div>
				{isLoading ?
					(
					<div className="loading-container">
						<div className="loading"></div>
					</div>
					):(
					<div className="proposal-container" id="pages">
						
						<div className="page">
							<Introduction refr={introRef}  data={data}/>
						</div>
						<div className="page">
							<Brief refr={briefRef} data={data}/>
						</div>
						<div className="page">
							<Scope refr={scopeRef} data={data}/>
						</div>
						<div className="page">
							<Tasks refr={tasksRef} data={data}/>
						</div>
						<div className="page">
							<Milestones refr={milestonesRef} data={data}/>
						</div>
						<div className="page">
							<Deliverables refr={deliverablesRef} data={data}/>
						</div>
						{/* <div className="page">
							<Components refr={componentsRef} data={data}/>
						</div> */}
						<div className="page">
							<Assumptions refr={assumptionsRef} data={data}/>
						</div>
						<div className="page">
							<Costs refr={costsRef} data={data}/>
						</div>
						<div className="page">
							<Signatures refr={signaturesRef} data={data}/>
						</div>
					</div>
					)
				}
				</>
			}
		</div>
	);
};

export default ProposalViewer;
