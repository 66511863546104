import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { proposalActionCreators } from "../../actions";
import EditorBlock from "./EditorBlock.js";
import EditorBlockItems from "./EditorBlockItems.js";
import EditorBlockCosts from "./EditorBlockCosts.js";
import EditorTasks from "./EditorTasks.js";
import DeleteModal from "./DeleteModal.js";

const ProposalEditor = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const data = useSelector((state) => state.proposal.data);
	const { isLoading, error, isSaving } = useSelector((state) => ({
		isLoading: state.proposal.isLoading,
		error: state.proposal.error,
		isSaving: state.proposal.isSaving,
	}));

	const {
		requestProposal,
		getProposals,
		saveProposal,
		deleteProposal,
	} = proposalActionCreators;

	const [newProposal, setNewProposal] = useState({
		id: "",
		modified_by: "",
		prepared_by: "",
		client: "",
		company: "",
		project_name: "",
		project_description: "",
		submitted: "",
		created_at: "",
		updated_at: "",
		overview: {
			paragraphs: [],
			items: [],
		},
		objectives: {
			paragraphs: [],
			items: [],
		},
		scope: {
			paragraphs: [],
			items: [],
		},
		tasks: {phases: []},
		deliverables: {
			paragraphs: [],
			items: [],
		},
		components: {
			paragraphs: [],
			items: [],
		},
		assumptions: {
			paragraphs: [],
			items: [],
		},
		milestones: {
			paragraphs: [],
			items: [],
		},
		costs: {
			paragraphs: [],
			items: [],
		},
		signed: 0,
		signors: 1
	});

	const [autoSave, setAutoSave] = useState(false);

	const [open, setOpen] = useState(false);
	const closeModal = () => {
		setOpen(false);
	};

	const handleChange = (event) => {
		event.preventDefault();
		setNewProposal({
			...newProposal,
			[event.target.name]: event.target.value,
		});
	};

	const handleMapChange = (key1, key2, index, event) => {
		event.preventDefault();
		const newArray = newProposal[key1][key2];
		newArray.splice(index, 1, event.target.value);
		setNewProposal({
			...newProposal,
			[key1]: {
				...newProposal[key1],
				[key2]: newArray,
			},
		});
	};

	const handleCostsChange = (costs) => {
		setNewProposal({
			...newProposal,
			'costs': {
				...newProposal['costs'],
				'items': costs
			}
		});
	}

	const handleObjectChange = (key1, key2, key3, index, event) => {
		event.preventDefault();
		let newArray = newProposal[key1][key2];
		newArray.splice(index, 1, {
			...newArray[index],
			[key3]: event.target.value,
		});
		setNewProposal({
			...newProposal,
			[key1]: {
				...newProposal[key1],
				[key2]: newArray,
			},
		});
	};

	const handleAddObject = (key1, key2, event) => {
		event.preventDefault();
		const newArray = newProposal[key1][key2];
		if (key1 === "costs") {
			newArray.push({
				hours: 0,
				name: "",
				price: 0,
			});
		} else {
			newArray.push({
				name: "",
				description: "",
			});
		}
		setNewProposal({
			...newProposal,
			[key1]: {
				...newProposal[key1],
				[key2]: newArray,
			},
		});
	};

	const handleAddParagraph = (key1, key2, event) => {
		event.preventDefault();
		const newArray = newProposal[key1][key2];
		newArray.push("");
		setNewProposal({
			...newProposal,
			[key1]: {
				...newProposal[key1],
				[key2]: newArray,
			},
		});
	};

	const handleRemoveParagraph = (key1, key2, index, event) => {
		event.preventDefault();
		const newArray = newProposal[key1][key2];
		newArray.splice(index, 1);
		setNewProposal({
			...newProposal,
			[key1]: {
				...newProposal[key1],
				[key2]: newArray,
			},
		});
	};

	const handleChangeSignors = (event) => {
		event.preventDefault();
		console.log(event.target.value);
		setNewProposal({
			...newProposal,
			signors: parseInt(event.target.value)
		});
	};

	const handlePhaseChange = (index, e) => {
		e.preventDefault();
		let newPhase = newProposal.tasks.phases;
		newPhase.splice(index, 1, {
			...newPhase[index],
			paragraph: e.target.value,
		});
		setNewProposal({
			...newProposal,
			tasks: {
				phases: newPhase
			}
		})
	}

	const handleTaskChange = (index, item_index, e) => {
		e.preventDefault()
		const newTasks = newProposal.tasks.phases
		newTasks[index].items.splice(item_index, 1, e.target.value)
		const key="tasks";
		setNewProposal({
			...newProposal,
			[key]: {
				phases: newTasks
			}
		})
	}

	const handleAddPhase = (e) => {
		e.preventDefault()
		const newPhase = newProposal.tasks
		newPhase.phases.push({
			paragraph: "",
			items: []
		})
		const key="tasks";
		setNewProposal({
			...newProposal,
			[key]: newPhase
		})
	}

	const handleRemovePhase = (e, index) => {
		e.preventDefault()
		const newTasks = newProposal.tasks.phases
		newTasks.splice(index, 1)
		const key="tasks";
		setNewProposal({
			...newProposal,
			[key]: {
				phases: newTasks
			}
		})
	}

	const handleAddTask = (index, e) => {
		e.preventDefault()
		const newTasks = newProposal.tasks.phases
		newTasks[index].items.push("")
		setNewProposal({
			...newProposal,
			tasks: {
				phases: newTasks
			}
		})
	}

	const handleRemoveTask = (index, item_index, e) => {
		e.preventDefault()
		const newTasks = newProposal.tasks.phases
		newTasks[index].items.splice(item_index, 1)
		const key="tasks";
		setNewProposal({
			...newProposal,
			[key]: {
				phases: newTasks
			}
		})
	}

	function sleep(ms) {
		return new Promise((res) => setTimeout(res, ms));
	}

	const save = async(e, mode) => {
		e.preventDefault();
		switch (mode) {
			case "close":
				console.log("Saving and closing...");
				dispatch(saveProposal(newProposal));
				if (!error) {
					const id = localStorage.getItem("id");
					await sleep(400)
					console.log("save and close reloading...");
					dispatch(getProposals(id));
					window.location.href = "/dashboard/proposals";
				}
				break;

			default:
				console.log("Saving", newProposal);
				dispatch(saveProposal(newProposal));
				break;
		}
	};

	const close = () => {
		window.location.href = "/dashboard/proposals";
	}

	const handleDelete = async (proposal_id, e) => {
		e.preventDefault();
		console.log("deleting...", proposal_id);
		const id = localStorage.getItem("id");
		dispatch(deleteProposal(id, proposal_id));
		closeModal()
		window.location.href = "/dashboard/proposals";
	};

	const dateString = (dateData) => {
		var date = new Date(dateData);
		var dateString = new Date(date.getTime()).toISOString().split("T")[0];
		return dateString
	}

	useEffect(() => {
		if (data) {
			console.log("proposal data", data);
			setNewProposal({
				id: data.id,
				modified_by: data.modified_by,
				prepared_by: data.prepared_by,
				client: data.client,
				company: data.company,
				project_name: data.project_name,
				project_description: data.project_description,
				submitted: dateString(data.submitted),
				created_at: data.created_at,
				updated_at: data.updated_at,
				overview: data.overview,
				objectives: data.objectives,
				scope: data.scope,
				tasks: data.tasks,
				deliverables: data.deliverables,
				components: data.components,
				assumptions: data.assumptions,
				milestones: data.milestones,
				costs: data.costs,
				signed: data.signed,
				signors: data.signors
			});
		}
	}, [data]);

	// function sleep(ms) {
	// 	return new Promise((res) => setTimeout(res, ms));
	// }

	// useEffect(() => {
	// 	const runAutoSave = async () => {
	// 		const sleepTime = 60000
	// 		if(autoSave){
	// 			console.log('Autosave loaded - timing:', sleepTime)
	// 		}
	// 		await sleep(sleepTime)
	// 		if(autoSave && sleepTime > 59999){
	// 			console.log('Autosaving...')
	// 			dispatch(saveProposal(newProposal));
	// 		}
	// 	}

	// runAutoSave()
	// }, [autoSave, dispatch, newProposal, saveProposal])

	useEffect(() => {
		if ( location.search && location.search.slice(1, 3) === "id") {
			const id = location.search.slice(4);
			dispatch(requestProposal(id));
		}
	}, [location, dispatch, requestProposal]);
	return (
		<div className="proposal-editor">
			{data === null ? (
				<div className="no-proposal-id" id="pages">
					Please enter a valid proposal id
					{error && <p>{error}</p>}
				</div>
			) : ( 
				<>
					{isLoading ? (
						<div className="loading-container">
							<div className="loading"></div>
						</div>
					) : (
						<>
							<div className="toolbar">
								{isSaving ? (
									<div className="loading-container">
										<div className="loading"></div>
									</div>
								) : (
									<>
										<div className="buttons">
											<Link
												to={`/proposal?id=${data.id}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												<button>Preview</button>
											</Link>
											<button
												className="gold"
												onClick={() => {
													navigator.clipboard.writeText(
														`${window.location.origin}/proposal?id=${data.id}`
													);
												}}
											>
												Copy URL
											</button>
											<button
												className="darkred"
												onClick={(e) => setOpen(true)}
											>
												Delete
											</button>
											<input
												type="checkbox"
												onChange={(e) => setAutoSave(!autoSave)}
												checked={autoSave}
											/>
											<label htmlFor="checkbox">Autosave</label>
											<button
												className="darkgreen"
												onClick={(e) => save(e, "")}
											>
												Save
											</button>
											<button onClick={(e) => save(e, "close")}>
												Save & Close
											</button>	
											<button className="gold" onClick={(e) => close(e)}>
												Close
											</button>

											<DeleteModal
												open={open}
												closeModal={closeModal}
												handleDelete={handleDelete}
												proposal={data}
											/>
										</div>
										{/* <div className="share-link">
										<p>
											<span>{window.location.origin}/proposal?id={data.id}</span>
										</p>
									</div> */}
									</>
								)}
							</div>
							{newProposal.signed ? (<>
								<div className="signed">
									<div className="modal">
										<h3>Cannot edit a signed proposal!</h3>
										<button onClick={(e) => close(e, "close")}>
											Go Back
										</button>
									</div>
								</div>
								<div className='editor'>
									<div className="editor-block column">
										<div className="editor-header">
											<h2>Signatures</h2>
										</div>
										<h3>Number of Signers</h3>
										<p>How many signatures are needed for this proposal.</p>
										<div className="block">
										<select  className='editor-input' name="signors" id="signors" onChange={handleChangeSignors} value={newProposal.signors} style={{width: 100}}>
											<option value={1}>1</option>
											<option value={2}>2</option>
											<option value={3}>3</option>
											<option value={4}>4</option>
											<option value={5}>5</option>
										</select>
										</div>
									</div>
								</div>
							</>):(
								<div className="editor">
									<div className="editor-block column">
										<div className="editor-header">
											<h2>{newProposal.project_name}</h2>
										</div>
										<div className="names-container">
											<div className="half-width">
												<label
													className="editor-input-label"
													htmlFor="project_name"
												>
													Project Name:
												</label>
												<input
													className="editor-input"
													id="project_name"
													type="text"
													name="project_name"
													value={newProposal.project_name}
													onChange={handleChange}
													placeholder="Project Name"
												></input>

												<label
													className="editor-input-label"
													htmlFor="project_description"
												>
													Project Description:
												</label>
												<input
													className="editor-input"
													id="project_description"
													type="text"
													name="project_description"
													value={newProposal.project_description}
													onChange={handleChange}
													placeholder="Project Name Long"
												></input>
											</div>

											<div className="half-width">
												<label className="editor-input-label" htmlFor="company">
													Company Name:
												</label>
												<input
													className="editor-input"
													id="company"
													type="text"
													name="company"
													value={newProposal.company}
													onChange={handleChange}
													placeholder="Company"
												></input>

												<label className="editor-input-label" htmlFor="client">
													Client Name:
												</label>
												<input
													className="editor-input"
													id="client"
													type="text"
													name="client"
													value={newProposal.client}
													onChange={handleChange}
													placeholder="Client"
												></input>
											</div>
										</div>

										<div className="names-container">
											<label className="editor-input-label" htmlFor="client">
												Submitted:
											</label>
											<input
												className="editor-input"
												id="submitted"
												type="date"
												name="submitted"
												value={newProposal.submitted}
												onChange={handleChange}
												placeholder="Submitted"
											></input>
										</div>
									</div>

									<div className="editor-block column">
										<div className="editor-header">
											<h2>Brief & Objectives</h2>
										</div>
										<h3>Project Overview</h3>
										<p>A brief outline of the project here might outline the problems to be solved and overcome, as well as explain how the new project will improve or solve the current issue at hand.</p>
										<p>Include the scope and tasks required to complete this project will follow the timelines outlined within in order to deliver the components at the associated costs listed.</p>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="overview"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Project Objectives</h3>
										<p>What standards must the new project fill? What functions must the new project have? How can the success be measured?</p>
										<div className="block">
											<EditorBlockItems
												data={newProposal}
												key1="objectives"
												key2="items"
												key3="description"
												name="Objective"
												handleObjectChange={handleObjectChange}
												handleAddObject={handleAddObject}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
									</div>

									<div className="editor-block column">
										<div className="editor-header">
											<h2>Scope</h2>
										</div>
										<h3>Project Scope</h3>
										<p>The previous page aimed to set the scene with a wide introduction to the project, and by contrast the project scope aims to narrow this down into exactly what will be delivered, along with a task list breaking down what has to be done into comprehensible steps. The project scope should be as detailed as possible for stress free project management and to prevent runaway or scope creep. It is extremely common for clients to ask for more at later stages of a project, so if a task or project element is not listed in the original scope then it gives you grounds to charge for the work.</p>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="scope"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Aim List</h3>
										<p><b>Primary:</b> Here set out the primary aim of the project or the most pressing matter. When the project is fully complete, what will the client have and how has it been designed to fully meet its requirements?</p>
										<p><b>Secondary:</b> Break down the primary aim further, so if the overall project is to achieve an aim, or fulfill a need, what features need to be built to complete the project? List everything here and ask your client to check nothing has been missed.</p>
										<p><b>Tertiary:</b> This final aim may list checks for the finished project, and/or list ongoing support options available for the client after the project is finished if applicable.</p>
										<div className="block">
											<EditorBlockItems
												data={newProposal}
												key1="scope"
												key2="items"
												key3="description"
												name="Aim"
												handleObjectChange={handleObjectChange}
												handleAddObject={handleAddObject}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
									</div>

									{/* <div className="editor-block column">
										<div className="editor-header">
											<h2>Tasks</h2>
										</div>
										<h3>Project Tasks</h3>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="tasks"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Task List</h3>
										<div className="block">
											<EditorBlockItems
												data={newProposal}
												key1="tasks"
												key2="items"
												key3="description"
												name="Task"
												handleObjectChange={handleObjectChange}
												handleAddObject={handleAddObject}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
									</div> */}

									<div className="editor-block column">
										<div className="editor-header">
											<h2>Tasks</h2>
										</div>
										<h3>Project Tasks</h3>
										<p><b>Describe the major phases and tasks:</b> What is the first logical step you must take in order to produce work that will move the project along to the middle stage? After this task, you might need to seek client sign-off and/or invoice settlement from your client before you move on to the next stage.</p>
										<div className="block">
											<EditorTasks
												data={newProposal}
												key1="tasks"
												key2="paragraphs"
												name="Paragraph"
												handlePhaseChange={handlePhaseChange}
												handleTaskChange={handleTaskChange}
												handleAddPhase={handleAddPhase}
												handleRemovePhase={handleRemovePhase}
												handleAddTask={handleAddTask}
												handleRemoveTask={handleRemoveTask}
											/>
										</div>
									</div>

									<div className="editor-block column">
										<div className="editor-header">
											<h2>Milestones</h2>
										</div>
										<h3>Project Milestones</h3>
										<p>Write a sentence to introduce the timeline and milestones. Making a plan like this is just as much in your favour as it is for your client. Putting key milestones in clarifies the stages the project will evolve through and will be critical to refer to.</p>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="milestones"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Milestones List</h3>
										<p>List the title of the milestone along with the due date here:</p>
										<div className="block">
											<EditorBlockItems
												data={newProposal}
												key1="milestones"
												key2="items"
												key3="due_date"
												name="Milestone"
												handleObjectChange={handleObjectChange}
												handleAddObject={handleAddObject}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
									</div>

									<div className="editor-block column">
										<div className="editor-header">
											<h2>Deliverables</h2>
										</div>
										<h3>Project Deliverables</h3>
										<p>Deliverables will refer to items that the client will receive from you at key stages of this project. They may consist of plans, draft versions of the final project or specifications.

After sending a deliverable, you will probably to seek sign-off and/or invoice settlement from your client before you move on to the next stage.

During a project, if the client knows what deliverable is to be expected next, they will be able to follow the project and likely bring forth fewer objections or amendments.</p>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="deliverables"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Deliverables List</h3>
										<p><b>First deliverable:</b> The first stage of any project is generally a plan of what will be built, coded or constructed. Great attention to detail at this stage ensures fewer questions or problems further down the line.</p>
										<p><b>Drafts and revisions:</b> With an exceptional plan in the bag, development can start on the project. When a draft version is ready, you can present this to the client and proceed to the final and/or test stages of the project.</p>
										<p><b>Final deliverable:</b> Once the client has signed off the final version, settled any outstanding invoices and is generally happy and satisfied, it's time to deliver the final components. It is critical to organise beforehand exactly how the project will get wrapped up. This helps manage your client's expectations, and secondly, avoids conflict at this final stage (when impressions are long lasting)!</p>
										<div className="block">
											<EditorBlockItems
												data={newProposal}
												key1="deliverables"
												key2="items"
												key3="description"
												name="Deliverable"
												handleObjectChange={handleObjectChange}
												handleAddObject={handleAddObject}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
									</div>

									{/* <div className="editor-block column">
										<div className="editor-header">
											<h2>Components</h2>
										</div>
										<h3>Project Components</h3>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="components"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Components List</h3>
										<div className="block">
											<EditorBlockItems
												data={newProposal}
												key1="components"
												key2="items"
												key3="description"
												name="Component"
												handleObjectChange={handleObjectChange}
												handleAddObject={handleAddObject}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
									</div> */}

									<div className="editor-block column">
										<div className="editor-header">
											<h2>Assumptions</h2>
										</div>
										<h3>Project Assumptions</h3>
										<p>Here you should flag up to your client any assumptions you have made in order for the project to go ahead.</p>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="assumptions"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Assumptions List</h3>
										<p> Be sure to include the list of <b>Terms and Conditions</b> you and the client will agree to operate under.</p>
										<div className="block">
											<EditorBlockItems
												data={newProposal}
												key1="assumptions"
												key2="items"
												key3="description"
												name="Assumption"
												handleObjectChange={handleObjectChange}
												handleAddObject={handleAddObject}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
									</div>

									<div className="editor-block column">
										<div className="editor-header">
											<h2>Costs</h2>
										</div>
										<h3>Project Costs</h3>
										<p>An outline of the costs for the design, development and testing of the site is estimated here followed by the total:</p>
										<div className="block">
											<EditorBlock
												data={newProposal}
												key1="costs"
												key2="paragraphs"
												name="Paragraph"
												handleMapChange={handleMapChange}
												handleAddParagraph={handleAddParagraph}
												handleRemoveParagraph={handleRemoveParagraph}
											/>
										</div>
										<h3>Costs List</h3>
										<p>Estimate how many hours, and the hourly price, per stage.  The total will be automatically calculated for you!</p>
										<div className="block">
											<EditorBlockCosts
												costs={newProposal.costs.items}
												handleChange={handleCostsChange}
											/>
										</div>
									</div>
									<div className="editor-block column">
										<div className="editor-header">
											<h2>Signatures</h2>
										</div>
										<h3>Number of Signers</h3>
										<p>How many signatures are needed for this proposal.</p>
										<div className="block">
										<select className='editor-input' name="signors" id="signors" onChange={handleChangeSignors} value={newProposal.signors} style={{width: 100}}>
											<option value={1}>1</option>
											<option value={2}>2</option>
											<option value={3}>3</option>
											<option value={4}>4</option>
											<option value={5}>5</option>
										</select>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default ProposalEditor;
