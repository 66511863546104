import { proposalActionTypes } from "../actions";

const {
	PROPOSAL_START,
	PROPOSAL_SUCCESS,
	PROPOSAL_FAIL,
	PROPOSALS_LIST_START,
    PROPOSALS_LIST_SUCCESS,
    PROPOSALS_LIST_FAIL,
	PROPOSALS_CREATE_START,
    PROPOSALS_CREATE_SUCCESS,
    PROPOSALS_CREATE_FAIL,
    PROPOSALS_SAVE_START,
    PROPOSALS_SAVE_SUCCESS,
    PROPOSALS_SAVE_FAIL,
    PROPOSALS_DELETE_START,
    PROPOSALS_DELETE_SUCCESS,
    PROPOSALS_DELETE_FAIL,
} = proposalActionTypes;

const initialState = {
	data: null,
	list: [],
	isLoading: false,
    isSaving: false,
	error: "",
};

export const proposal = (state = initialState, action) => {
	switch (action.type) {
		case PROPOSAL_START:
			return {
				...state,
				isLoading: true,
			};
		case PROPOSAL_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
			};
		case PROPOSAL_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
        //getProposals
		case PROPOSALS_LIST_START:
			return {
				...state,
				isLoading: true,
			};
		case PROPOSALS_LIST_SUCCESS:
			return {
				...state,
				list: action.payload,
				isLoading: false,
			};
		case PROPOSALS_LIST_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
        //createProposal - uses "list"
        case PROPOSALS_CREATE_START:
			return {
				...state,
				isLoading: true,
			};
		case PROPOSALS_CREATE_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
			};
		case PROPOSALS_CREATE_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
        //saveProposal
        case PROPOSALS_SAVE_START:
			return {
				...state,
				isSaving: true,
			};
		case PROPOSALS_SAVE_SUCCESS:
			return {
				...state,
				// data: action.payload,
				isSaving: false,
			};
		case PROPOSALS_SAVE_FAIL:
			return {
				...state,
				isSaving: false,
				error: action.payload,
			};
        //deleteProposal
        case PROPOSALS_DELETE_START:
			return {
				...state,
				isSaving: true,
			};
		case PROPOSALS_DELETE_SUCCESS:
			return {
				...state,
				// data: action.payload,
				isSaving: false,
			};
		case PROPOSALS_DELETE_FAIL:
			return {
				...state,
				isSaving: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
