import React from "react";

import zora_proposal_header from "../../../assets/zora-proposal-header-small.jpg";

const Assumptions = (props) => {
	const { refr, data } = props;

	return (
		<div className="page-content" id="tasks" ref={refr}>
			<div className="header-image">
				<img src={zora_proposal_header} alt="Banner" />
			</div>
			<div className="dark-box">
				<h1>Project Assumptions</h1>
				<h2>Key information we are working with</h2>
			</div>
			<div className="white-box">
				<h3>PROJECT ASSUMPTIONS</h3>
				{data.assumptions.paragraphs.length > 0 && (
					<>
						{data.assumptions.paragraphs.map((paragraph, index) => {
							return <p key={index}>{paragraph}</p>;
						})}
					</>
				)}
				<ul>
					{data.assumptions.items.length > 0 && (
						<>
							{data.assumptions.items.map((item, index) => {
								return (
									<li className="item" id="assumption-item" key={index}>
										{item.description}
									</li>
								);
							})}
						</>
					)}
				</ul>
			</div>
			<div className="page-footer">
				<div className="line"></div>
				<p className="uppercase small-font">
					20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672
				</p>
			</div>
		</div>
	);
};

export default Assumptions;
