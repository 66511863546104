import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { proposalActionCreators } from "../../../actions";
import { convertDate } from "../../../utils";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";

import zora_proposal_header from "../../../assets/zora-proposal-header-small.jpg";

const Signatures = (props) => {
	const dispatch = useDispatch();
	const { error, isSaving } = useSelector((state) => ({
		error: state.proposal.error,
		isSaving: state.proposal.isSaving,
	}));

	const { saveSignature } = proposalActionCreators;

	const data = props.data;
	let num_unsigned = data.signors - data.signed;

	const sigCanvas = useRef({});

	const [approval, setApproval] = useState({
		signature_name: '',
		signature_image: null,
	});

	const [allowSubmit, setAllowSubmit] = useState(false);

	const handleChange = (e) => {
		e.preventDefault();
		setApproval({
			...approval,
			signature_name: e.target.value
		});
	};

	const clear = () => sigCanvas.current.clear();

	const save = (close) => {
		let im = null;
		if (sigCanvas.current.isEmpty()) {
			setAllowSubmit(false);
		} else {
			im = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
		}
		setApproval({
			...approval,
			signature_image: im
		});
		close();
	};

	const submitApproval = (e, i) => {
		e.preventDefault();
		const key = Date.now();
		dispatch(saveSignature(data.id, key, approval));
	};

	let signed_date = data.signed_date.map(date => convertDate(new Date(date)));

	useEffect(() => {
		if (approval.signature_name.length > 0 && approval.signature_image) {
			setAllowSubmit(true);
		}
	}, [approval]);

	console.log('Signatures????');
	console.log(data);
	return (
		<div className="page-content" id="tasks" ref={props.refr}>
			<div className="header-image">
				<img src={zora_proposal_header} alt="Banner" />
			</div>
			<div className="dark-box">
				<h1>Signatures</h1>
				<h2>Sign Electronically Below</h2>
			</div>
			<div className="white-box">
				<h3>Acceptance</h3>
				<p>
					<b>
						To accept the conditions set out in this Statement of Work, simply
						type and sign your name below.
					</b>
				</p>
				<p>We look forward to working with you on {data.project_name}.</p>
			</div>
			{data.signature_name.map((signature_name, i) => <>
				<div className="signature-box signed">
					<h3>Thank you!</h3>
					<div className="name-signature">
						<div className="input-box">
							<div className="input-signed">{signature_name}</div>
							<div className="input-label">Name</div>
						</div>
						<div className="input-box">
							<div className="input-signed">
								<img
									src={data.signature_image[i]}
									alt="Client Signature"
									style={{
										display: "block",
										margin: "0 auto",
										maxHeight: "45px",
										maxWidth: "190px",
									}}
								/>
							</div>
							<div className="input-label">Signature</div>
						</div>
					</div>
					<div className="signed-date">
						<h4>Signed: {signed_date[i]}</h4>
					</div>
				</div>
			</>)}
			{num_unsigned ?
				<form className="signature-box" onSubmit={(e) => submitApproval(e)}>
					<h3>To approve this proposal, type and sign your name below:</h3>
					<div className="name-signature">
						<div className="input-box">
							<input
								className="name"
								id="name"
								type="name"
								name="signature_name"
								value={approval.signature_name}
								onChange={handleChange}
								placeholder="Please type your name"
								required
							></input>
							<div className="input-label">Name</div>
						</div>
						<Popup
							modal
							trigger={
								<div className="input-box">
									<div className="signature">
										{!num_unsigned ? (
											<img
												src={approval.signature_image}
												alt="Client Signature"
												style={{
													display: "block",
													margin: "0 auto",
													maxHeight: "45px",
													maxWidth: "190px",
												}}
											/>
										) : (
											<>
												{approval.signature_image ? (
													<img
														src={approval.signature_image}
														alt="Client Signature"
														style={{
															display: "block",
															margin: "0 auto",
															maxHeight: "45px",
															maxWidth: "190px",
														}}
													/>
												) : (
													<p>Click here to sign</p>
												)}
											</>
										)}
									</div>
									<div className="input-label">Signature</div>
								</div>
							}
							closeOnDocumentClick={true}
						>
							{(close) => (
								<>
									<button className="close" onClick={close}>
										&times;
									</button>
									<SignaturePad
										ref={sigCanvas}
										canvasProps={{
											className: "signatureCanvas",
										}}
									/>
									<div className="signature-buttons">
										<button onClick={clear}>Clear</button>
										<button onClick={(e) => save(close)}>Save</button>
									</div>
								</>
							)}
						</Popup>
					</div>
					<div className="name-signature">
						{isSaving ? (
							<div className="loading-container">
								<div className="loading"></div>
							</div>
						) : (
							<button className="submit-approval" disabled={!allowSubmit}>
								Submit
							</button>
						)}
					</div>
					{error && <div className="error">{error}</div>}
				</form> : null
			}
			

			<div className="page-footer">
				<div className="line"></div>
				<p className="uppercase small-font">
					20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672
				</p>
			</div>
		</div>
	);
};

export default Signatures;
