import React, { useState, useEffect } from "react";

import useScript from '../../utils/useScript.js';

const Tableau = (props) => {
	// console.log('tableau', props.tableauLoad)

	const refreshView = () => {
		let iframeRefresh = document.querySelectorAll("iframe");
		console.log("refresh");
		if (iframeRefresh.length > 0) {
			console.log("already loaded!");
		} else {
			console.log("refreshing...");
			window.location.reload();
		}
	};

	function sleep(ms) {
		return new Promise((res) => setTimeout(res, ms));
	}

	// const [width, setWidth]   = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const updateDimensions = () => {
		// setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};

    useScript("https://prod-useast-b.online.tableau.com/javascripts/api/viz_v1.js", "tableauScript", ()=> console.log('script callback'))

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	useEffect(() => {
		let iframeExists = document.querySelectorAll("iframe");
		const checkIfLoaded = async function () {
			if (iframeExists.length > 0) {
			} else {
				await sleep(500);
				refreshView();
				iframeExists = document.querySelectorAll("iframe");
			}
		};

		checkIfLoaded();
	}, []);

	return (
		<div className="overview">
			
			{true ? (
				<div className="tableauPlaceholder" id="tableau" style={{ width: "1030px", height: "950px" }}>
					<object className="tableauViz" width="1040" height={height - 50} style={{ display: "none" }}>
						<param name="host_url" value="https%3A%2F%2Fprod-useast-b.online.tableau.com%2F"/>{" "}
						<param name="embed_code_version" value="3" />{" "}
						<param name="site_root" value="&#47;t&#47;zdigital" />
						<param name='name' value='Updated_CupcakesCashmereDashboard9-26-21&#47;Dashboard1&#47;ee5a7358-1498-46ef-8066-e1fea45c63e4&#47;9977b007-8b37-4596-85bc-9d5bafaa0416' />
						<param name="tabs" value="no" />
						<param name="toolbar" value="no" />
						<param name="showAppBanner" value="false" />
						<param name="refresh" value="yes" />
					</object>
				</div>
			) : (
				<div>Loading...</div>
			)}
		</div>
	);
};

export default Tableau;


/*

<div className="tableauPlaceholder" id="tableau" style={{ width: "1030px", height: "950px" }}>
					<object className="tableauViz" width="1040" height={height - 50} style={{ display: "none" }}>
						<param name="host_url" value="https%3A%2F%2Fprod-useast-b.online.tableau.com%2F"/>{" "}
						<param name="embed_code_version" value="3" />{" "}
						<param name="site_root" value="&#47;t&#47;zdigital" />
						<param name='name' value='Updated_CupcakesCashmereDashboard9-26-21&#47;CupcakesCashmere&#47;1cbe3acb-0669-4503-8c6b-1954ebced0fb&#47;12557e0c-358a-4cb5-a0a8-41242eb1c139' />
						<param name="tabs" value="no" />
						<param name="toolbar" value="no" />
						<param name="showAppBanner" value="false" />
						<param name="refresh" value="yes" />
					</object>
				</div>

				*/