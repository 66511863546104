import React from "react";


class EditorBlockCosts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			total: this.computeTotal()
		}

		this.addCost = this.addCost.bind(this);
		this.removeCost = this.removeCost.bind(this);
		this.setName = this.setName.bind(this);
		this.setType = this.setType.bind(this);
		this.setPrice = this.setPrice.bind(this);
		this.setDuration = this.setDuration.bind(this);
	}

	componentDidUpdate(_) {
		let total = this.computeTotal();
		if (total !== this.state.total) {
			this.setState({total});
		}
	}

	computeTotal() {
		let total = 0;
		this.props.costs.map(cost => {
			if (cost.type === 'hourly' || cost.type === 'monthly')
				total += cost.duration * cost.price || 0;
			else if (cost.type === 'project')
				total += cost.price || 0;
			return null;
		});
		return total;
	}

	addCost() {
		let newCost = { name: "", type: "hourly", duration: 0, price: 0}
		this.props.costs.push(newCost);
		this.props.handleChange(this.props.costs);
	}

	removeCost(i) {
		this.props.costs.splice(i, 1);
		this.props.handleChange(this.props.costs);
	}

	setName(i, name) {
		this.props.costs[i].name = name;
		this.props.handleChange(this.props.costs);
	}

	setType(i, type) {
		this.props.costs[i].type = type;
		this.props.costs[i].duration = 0;
		this.props.handleChange(this.props.costs);
	}

	setPrice(i, price) {
		this.props.costs[i].price = parseFloat(price);
		this.props.handleChange(this.props.costs);
	}

	setDuration(i, duration) {
		this.props.costs[i].duration = parseFloat(duration);
		this.props.handleChange(this.props.costs);
	}

	render () {
		return (
			<>
				{this.props.costs.map((cost, index) => {
					return (
						<div className="editor-map" key={index}>
							<div className="label-container">
									<label className="editor-input-label">Cost {index + 1}:</label>
									<button className="remove-button darkred" onClick={(e) => this.removeCost(index)}>Remove</button>
							</div>
	
							<textarea
								className={`editor-input`}
								type="text"
								name={`costs-items-name-${index}`}
								value={cost.name}
								onChange={(e) => this.setName(index, e.target.value)}
								placeholder='Cost Name'
							/>
							
							<div className="costs-inputs">

								<label className="editor-input-label">Cost Type:</label>
								<select className='editor-input' value={cost.type} onChange={(e) => this.setType(index, e.target.value)}>
									<option value='hourly'>Hourly</option>
									<option value='monthly'>Monthly</option>
									<option value='project'>Whole Project</option>
								</select>


								<div className="inputs-container">
									{cost.type !== 'project' && <>
										<label className="editor-input-label">{cost.type === 'hourly' ? 'Hours' : 'Months'}:</label>
										<input
											className="editor-input input-costs"
											type="number"
											name={`costs-items-name-${index}`}
											value={cost.duration}
											onChange={(e) => this.setDuration(index, e.target.value) }
											placeholder={cost.type === 'hourly' ? 'Hours' : 'Months'}
										/>
									</>}

									<label className="editor-input-label">{cost.type === 'hourly' ? 'Hourly' : cost.type === 'monthly' ? 'Monthly' : 'Project'} Price:</label>
									<input
										className="editor-input input-costs"
										type="number"
										name={`costs-items-name-${index}`}
										value={cost.price}
										onChange={(e) => this.setPrice(index, e.target.value) }
										placeholder={`Price`}
									/>
								</div>
	
								<div className="inputs-container">
									<label className="editor-input-label">Total:</label>
									<input
										className="editor-input input-costs"
										type="text"
										name={`costs-items-total-${index}`}
										value={`$${(cost.type === 'hourly' || cost.type === 'monthly' ? cost.duration * cost.price : cost.price) || 0}`}
										disabled={true}
									/>
								</div>
							</div>
						</div>
					);
				})}
				<div className="total-container">
					<button className="add-button" onClick={(e) => this.addCost()}>Add Cost</button>
					<p>Project total: <b>${this.state.total}</b></p>
				</div>
			</>
		);
	}
}

export default EditorBlockCosts;
