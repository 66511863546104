import { notificationActionTypes } from '../actions'

const { NOTIFICATION_SET, NOTIFICATION_CLEAR } = notificationActionTypes

const initialState = { message: null }

export const notifications = (state = initialState, action) =>{
    switch(action.type) {
        case NOTIFICATION_SET:
          return {
            ...state,
            message: action.message
          }
        case NOTIFICATION_CLEAR:
          return {
            ...state,
            message: null
          }
        default:
            return state;
    }
}