import React, { useState } from "react";

import DeleteModal from "./DeleteModal.js";

import threedots from "../../assets/threedots.svg";
import fileicon from "../../assets/fileicon.svg";
import greencheck from "../../assets/greencheck.png";

const ProposalCard = (props) => {
	const { proposal, index, handleDelete, handleCopy } = props;

	const [showMenu, setShowMenu] = useState(false);
	const [hoverMenuItem, setHoverMenuItem] = useState(false);

	const [open, setOpen] = useState(false);
	const closeModal = () => {
		setOpen(false);
		setShowMenu(false);
	};

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const editProposal = (proposal_id) => {
		if (!hoverMenuItem && !showMenu) {
			window.location.href = `/dashboard/proposaleditor?id=${proposal_id}`;
		}
	};

	const viewProposalMenu = (proposal_id) => {
		const newWindow = window.open(`/proposal?id=${proposal_id}`, "_blank");
		newWindow.focus();
	};

	const editProposalMenu = (proposal_id) => {
		window.location.href = `/dashboard/proposaleditor?id=${proposal_id}`
	};

	const handleClick = (proposal_id) => {
		if (!hoverMenuItem && !showMenu) {
			if (proposal.signed === proposal.signors){
				viewProposalMenu(proposal_id)
			} else {
				editProposal(proposal_id)
			}
		}
	}

	return (
		<div
			className={`proposal-card list-${index + 1}`}
			key={index}
			onClick={() => handleClick(proposal.id)}
			onMouseLeave={() => {
				setShowMenu(false);
			}}
		>
			<div
				className="dots"
				onClick={toggleMenu}
				onMouseOver={() => {
					setShowMenu(true);
					setHoverMenuItem(true);
				}}
				onMouseLeave={() => {
					setHoverMenuItem(false);
				}}
			>
				<img src={threedots} alt="Menu" />
			</div>
			{showMenu && (
				<div className="menu">
					<div
						className="menu-item"
						onClick={() => viewProposalMenu(proposal.id)}
					>
						View
					</div>
					{proposal.signed !== proposal.signors && (
						<div
							className="menu-item"
							onClick={() => editProposalMenu(proposal.id)}
						>
							Edit
						</div>
					)}
					<div
						className="menu-item"
						onMouseOver={(e) => setHoverMenuItem(true)}
						onMouseLeave={(e) => setHoverMenuItem(false)}
						onClick={(e) => handleCopy(e, proposal)}
					>
						Copy
					</div>
					<div
						className="menu-item"
						onClick={() => setOpen((o) => !o)}
						onMouseOver={(e) => setHoverMenuItem(true)}
						onMouseLeave={(e) => setHoverMenuItem(false)}
					>
						Delete
					</div>
				</div>
			)}
			<div className="icon">
				<img src={fileicon} alt="Proposal" />
					<div className="signed-notification">
						{proposal.signed === proposal.signors && <img src={greencheck} alt="Green Checkmark" /> }
					</div>
			</div>
			<div className="tags">
				<div style={{fontSize: '.75em'}}>Signed: {proposal.signed}/{proposal.signors}</div>
				<b>{proposal.project_name}</b>
				<br />
				{proposal.company}
				<br />
				{proposal.client}
			</div>
			<DeleteModal
				open={open}
				closeModal={closeModal}
				handleDelete={handleDelete}
				proposal={proposal}
			/>
			{/* <Popup open={open} closeOnDocumentClick onClose={closeModal}>
				<div className="modal">
					<button className="close" onClick={closeModal}>
						&times;
					</button>
                    <p>Are you sure you want to delete <b>{proposal.project_name}</b>?</p>
                    <div className="delete-buttons">
                        <button onClick={(e) => handleDelete(proposal.id, e)}>Delete</button>
                        <button className="darkred" onClick={closeModal}>Cancel</button>
                    </div>
				</div>
			</Popup> */}
		</div>
	);
};

export default ProposalCard;
