
import { PDFDownloadLink, Document, Page, View, Text, Image, Font } from '@react-pdf/renderer';
import zora_proposal_header from "../../assets/zora-proposal-header-small.jpg";
import intro_banner from "../../assets/zora-proposal-intro.jpg";
import font from '../../assets/fonts/GillSansLight.otf';
import fontBold from '../../assets/fonts/GillSans.otf';

Font.register({ family: 'Gill Sans', fonts: [{src: font, fontWeight: 'normal'}, { src: fontBold, fontWeight: 'bold' }] });
let pageStyle = {position: 'relative', fontFamily: 'Gill Sans', fontSize: 12, paddingBottom: 50};
let headerStyle = {color: '#FEBD11', fontWeight: 700, fontSize: 18, marginBottom: 8}
let border = '1px solid black';
//backgroundColor: 'lightgray',
//color: '#e89624'

const makePage = (title, subtitle, content) => {
  return (
    <Page wrap size='A4' style={pageStyle}>
      <Image fixed src={zora_proposal_header} />
      <View fixed style={{ backgroundColor: '#3b3054', textAlign: 'center', color: 'white', fontWeight: 'bold', marginBottom: 32}}>  
        <Text style={{fontSize: 32, margin: 12}}>{title}</Text>
        <Text style={{fontSize: 24, margin: 12}}>{subtitle}</Text>
      </View>
      <View style={{paddingLeft: 32, paddingRight: 32}}>
        {content}
      </View>
      <View fixed style={{width: '100%', position: 'absolute', bottom: 10, paddingTop: 5, borderTop: '1px solid #e89624', fontSize: 12, textAlign: 'center', color: '#663293'}}>
        <Text>20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672</Text>
      </View>
    </Page>
  )
}

const Introduction = (props) => {  
  let data = props.data;
  const dateString = (dateData) => {
    var date = new Date(dateData);
    var dateString = new Date(date.getTime()).toISOString().split("T")[0];
    let dsr = dateString.split("-")
    return `${dsr[1]}/${dsr[2]}/${dsr[0]}`
  }
  let submitted = dateString(data.submitted);
  
    return (<Page style={{...pageStyle, textAlign: 'center'}}>
      <Image src={intro_banner} />
      <View style={{fontWeight: 'bold', paddingBottom: 50}}>
        <Text style={{color: '#FEBD11', fontSize: 32, padding: '30px 0px'}}>PROJECT</Text>
        <Text style={{fontSize: 22}}>{data.project_name}</Text>
        <Text style={{fontSize: 18}}>{data.project_description}</Text>
      </View>
      <View>
        <Text style={{padding: '8px 0px'}}>Proposal for <Text style={{fontWeight: 'bold'}}>{data.company}</Text></Text>
        <Text style={{padding: '8px 0px'}}>Prepared by <Text style={{fontWeight: 'bold'}}>{data.prepared_by}</Text></Text>
        <Text style={{fontWeight: 'normal', padding: '5px 0px'}}>Submitted <Text style={{fontWeight: 'bold'}}>{submitted}</Text></Text>
      </View>
      <View style={{position: 'absolute', bottom: 20, color: 'rgb(102, 50, 147)', width: '100%'}}>
        <Text style={{padding: '5px 0px'}}>Thank you for the opportunity to propose this project.</Text>
        <Text style={{padding: '5px 0px'}}> We look forward to exceeding your expectations.</Text>
      </View>
      </Page>
    );
}

const Brief = (props) => {
  let data = props.data;
  return makePage("Brief & Objectives", "What we are setting out to achieve", <>
    <Text style={headerStyle}>Project Overview</Text>
    {data.overview.paragraphs.map((paragraph, ind) => <Text key={ind}>{paragraph}</Text>)}
    <Text style={{...headerStyle, marginTop: 12}}>Project Objectives</Text>
    {data.objectives.items.map((item, ind) => <Text style={{marginBottom: 12}}><Text style={{fontWeight: 'bold', marginBottom: 4}}>{item.name.toUpperCase()}: </Text>{item.description}</Text>)}
  </>)
}

const Scope = (props) => {
  let data = props.data;
  return makePage("Project Scope", "Exactly what this project will involve", <>
    <Text style={headerStyle}>Project Scope</Text>
    {data.scope.paragraphs.map((paragraph, ind) => <Text key={ind}>{paragraph}</Text>)}
    <Text style={{marginTop: 16, marginBottom: 12}}>Below outlines the scope of {data.project_name}:</Text>
    {data.scope.items.map((item, index) => <>
      <Text style={{fontWeight: 'bold', marginBottom: 4}}>{index + 1}. {item.name.toUpperCase()}</Text>
      <Text style={{marginBottom: 10}}>{item.description}</Text>
    </>)}
  </>)
}

const Tasks = (props) => {
  let data = props.data
  return makePage("Project Tasks", "Exactly what this project will involve", <>
    <Text style={headerStyle}>Project Tasks</Text>
    {data.tasks.phases.map((task, index) => <>
      <Text style={{marginTop: 22, marginBottom: 14}}><Text style={{fontWeight: 'bold'}}>PHASE {index + 1}:</Text> {task.paragraph}</Text>
      {task.items.map((item, item_index) => <Text><Text style={{fontWeight: 'bold', marginBottom: 3}}>Task {item_index + 1}: </Text>{item}</Text>)}
    </>)}
  </>);

}

const Milestones = (props) => {
  let data = props.data;
  return makePage("Project Milestones", "Keeping the project on track", <>
    <Text style={headerStyle}>KEY MILTESTONES</Text>
    {data.milestones.paragraphs.map((paragraph, index) => <Text>{paragraph}</Text>)}
    <Text style={{fontWeight: 'bold', marginTop: 8}}>We have drafted the below schedule for this project and its relevant stages:</Text>
    <View style={{marginTop: 32, fontWeight: 'bold', fontSize: 18, textAlign: 'center', display: 'flex', flexDirection: 'row', border: '1px solid black', backgroundColor: '#3b3054', color: 'white'}}>
      <Text style={{padding: 14, width: '50%', borderRight: '1px solid black',}}>PHASE</Text>
      <Text style={{padding: 14, width: '50%'}}>DUE DATE</Text>
    </View>
    {data.milestones.items.map((item, index) => <>
      <View style={{height: 35, textAlign: 'center', display: 'flex', flexDirection: 'row', borderLeft: border, borderRight: border, borderBottom: border, backgroundColor: (index % 2 === 1) ? 'rgba(87, 12, 131, 0.055)' : 'white'}}>
        <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%', borderRight: '1px solid black'}}>{item.name}</Text>
        <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%'}}>{item.due_date}</Text>
      </View>
    </>)}
  </>)
}

const Deliverables = (props) => {
  let data = props.data;
  return makePage("Deliverables", "What you will receive", <>
    <Text style={headerStyle}>DELIVERABLES</Text>
    {data.deliverables.paragraphs.map((paragraph, index) => <Text style={{marginBottom: 8}}>{paragraph}</Text>)}
    {data.deliverables.items.map((item, index) => <>
      <Text style={{fontWeight: 'bold', marginBottom: 3}}>DELIVERABLE {index + 1}: {item.name.toUpperCase()}</Text>
      <Text style={{marginBottom: 8}}>{item.description}</Text>
    </>)}
  </>)
}

const Assumptions = (props) => {
  let data = props.data;
  return makePage("Project Assumptions", "Key information we are working with", <>
    <Text style={headerStyle}>PROJECT ASSUMPTIONS</Text>
    {data.assumptions.paragraphs.map((paragraph, index) => <Text style={{marginBottom: 8}}>{paragraph}</Text>)}
    {data.assumptions.items.map((item, index) => <Text style={{marginBottom: 3}}><Text style={{fontWeight: 'bold', height: 0}}>• </Text>{item.description}</Text>)}
  </>)
}

const Costs = (props) => {
  let data = props.data;
  let total = 0;
  data.costs.items.map(cost => {
    if (cost.type === 'hourly' || cost.type === 'monthly')
      total += cost.duration * cost.price || 0;
    else if (cost.type === 'project')
      total += cost.price || 0;
    return null;
  })
  return makePage("Project Costs", "Keeping the project on budget", <>
    <Text style={headerStyle}>Final Costs & Invoice Schedule</Text>
    {data.costs.paragraphs.map((paragraph, index) => <Text>{paragraph}</Text>)}
    <View style={{marginTop: 32, fontWeight: 'bold', fontSize: 18, textAlign: 'center', display: 'flex', flexDirection: 'row', border: '1px solid black', backgroundColor: '#3b3054', color: 'white'}}>
      <Text style={{padding: 14, width: '50%', borderRight: '1px solid black',}}>STAGE</Text>
      <Text style={{padding: 14, width: '50%'}}>ESTIMATE</Text>
    </View>
    {data.costs.items.map((item, index) => <>
      <View style={{height: 35, textAlign: 'center', display: 'flex', flexDirection: 'row', borderLeft: border, borderRight: border, borderBottom: border, backgroundColor: (index % 2 === 1) ? 'rgba(87, 12, 131, 0.055)' : 'white'}}>
        <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%', borderRight: '1px solid black'}}>{item.name}</Text>
        {item.type === 'hourly' && <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%'}}>{item.duration || 0} hour(s) at ${item.price || 0}/hr</Text>}
        {item.type === 'monthly' && <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%'}}>{item.duration || 0} month(s) at ${item.price || 0}/month</Text>}
        {item.type === 'project' && <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%'}}>${item.price || 0} fixed cost</Text>}
      </View>
    </>)}
    <View style={{height: 35, fontWeight: 'bold', textAlign: 'center', display: 'flex', flexDirection: 'row', borderLeft: border, borderRight: border, borderBottom: border, backgroundColor: (data.costs.items.length % 2 === 1) ? 'rgba(87, 12, 131, 0.055)' : 'white'}}>
      <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%', borderRight: '1px solid black'}}>TOTAL</Text>
      <Text style={{paddingTop: 11, paddingBottom: 11, width: '50%'}}>${total}</Text>
    </View>
  </>)
}

const Signatures = (props) => {
  let data = props.data;
  let unsigned = Array(data.signors - data.signed).fill(null);
  return makePage("Signatures", "Sign Below", <>
    <Text style={headerStyle}>ACCEPTANCE</Text>
    <Text style={{fontWeight: 'bold', marginBottom: 8}}>To accept the conditions set out in this Statement of Work, simply type and sign your name below.</Text>
    <Text style={{marginBottom: 8}}>We look forward to working with you on {data.project_name}.</Text>
    <View style={{ backgroundColor: 'white'}}>
      {data.signature_name.map((s, i) => 
        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
          <View>
            <Text style={{width: 200, fontSize: 14, marginTop: 'auto', borderBottom: '1px solid black', marginBottom: 10}}>{s}</Text>
            <Text>Name</Text>
          </View>
          <View>
            <Image src={data.signature_image[i]} style={{width: 200, borderBottom: '1px solid black', marginBottom: 10}} />
            <Text>Signature</Text>
          </View>
        </View>
      )}
      {unsigned.map(_ => 
        <View style={{display: 'flex', flexDirection: 'row', height: 100, justifyContent: 'space-around'}}>
          <View>
            <Text style={{width: 200, borderBottom: '1px solid black', marginTop: 'auto', marginBottom: 10}}></Text>
            <Text>Name</Text>
          </View>
          <View>
            <Text style={{width: 200, borderBottom: '1px solid black', marginTop: 'auto', marginBottom: 10}}></Text>
            <Text>Signature</Text>
          </View>
        </View>
      )}
    </View>
  </>)
}

const ProposalPrinter = (props) => {
  console.log(props);
  const MyDoc = () => (<Document>
    <Introduction {...props} />
    <Brief {...props}/>
    <Scope {...props}/>
    <Tasks {...props}/>
    <Milestones {...props}/>
    <Deliverables {...props}/>
    <Assumptions {...props}/>
    <Costs {...props}/>
    <Signatures {...props}/>
  </Document>);

return (<>
  <PDFDownloadLink style={{}}document={<MyDoc />}>
    Download PDF
  </PDFDownloadLink>
</>);
}

export default ProposalPrinter;