import React, { useState, useEffect } from "react";

import zora_proposal_header from "../../../assets/zora-proposal-header-small.jpg";

const Costs = (props) => {

	const {refr, data } = props;

	const [price, setPrice] = useState(0)

	useEffect(() => {
		const calcPrice = () => {
			var total = 0;
			data.costs.items.map(cost => {
				if (cost.type === 'hourly' || cost.type === 'monthly')
					total += cost.duration * cost.price || 0;
				else if (cost.type === 'project')
					total += cost.price || 0;
				return null;
			})
			setPrice(total)
		}
		calcPrice()
	}, [data.costs.items])

	return (
		<div className="page-content" id="tasks" ref={refr}>
			<div className="header-image">
				<img src={zora_proposal_header} alt="Banner" />
			</div>
			<div className="dark-box">
				<h1>Project Costs</h1>
				<h2>Keeping the project on budget</h2>
			</div>
			<div className="white-box">
				<h3>Final Costs & Invoice Schedule</h3>
				{data.costs.paragraphs.length > 0 &&
					<>
						{data.costs.paragraphs.map((paragraph, index) =>{
							return (
								<p key={index}>{paragraph}</p>
							)
						})}
					</>
				}	
			</div>
			<div className="box">
				<div className="table">
					<div className="table-header">
						<div className="left-column">
							<h3>STAGE</h3>
						</div>
						<div className="right-column">
							<h3>ESTIMATE</h3>
						</div>
					</div>
					{data.costs.items.length > 0 &&
						<>
							{data.costs.items.map((item, index) =>{
								return (
									<div className="table-row" key={index}>
										<div className="left-column">
											<h4>{item.name}</h4>
										</div>
										<div className="right-column">
											{item.type === 'hourly' && <h4>{item.duration || 0} hour(s) at ${item.price || 0}/hr</h4>}
											{item.type === 'monthly' && <h4>{item.duration || 0} month(s) at ${item.price || 0}/month</h4>}
											{item.type === 'project' && <h4>${item.price || 0} fixed cost</h4>}
										</div>
									</div>
								)
							})}
						</>
					}
					<div className="table-row total">
						<div className="left-column">
							<h4>Total</h4>
						</div>
						<div className="right-column">
							<h4>${price}</h4>
						</div>
					</div>
				</div>
			</div>
			<div className="page-footer">
				<div className="line"></div>
                <p className="uppercase small-font">20 W. Kinzie St, 17th Floor Chicago info@zora.digital 872.216.9672</p>
            </div>
		</div>
	);
};

export default Costs;
