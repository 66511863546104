import React from "react";

const EditorBlockItems = (props) => {
    const { data, key1, key2, key3, name, handleObjectChange, handleAddObject, handleRemoveParagraph } = props
	return (
		<>
			{data[key1][key2].map((item, index) => {
				return (
					<div className="editor-map" key={index}>
						<div className="label-container">
						    <label className="editor-input-label">{name} {index + 1}:</label>
                            <button className="remove-button darkred" onClick={(e) => handleRemoveParagraph(key1, key2, index, e)}>Remove</button>
                        </div>
						{name !== "Assumption" &&
							<textarea
								className={`editor-input`}
								type="text"
								name={`${key1}-${key2}-name-${index}`}
								value={item.name}
								onChange={(e) =>
									handleObjectChange(key1, key2, "name", index, e)
								}
								placeholder={`${name} Name`}
							></textarea>
						}
						<textarea
							className={`editor-input input-description`}
							type="text"
							name={`${key1}-${key2}-${key3}-${index}`}
							value={item[key3]}
							onChange={(e) =>
								handleObjectChange(key1, key2, key3, index, e)
							}
							placeholder={`${name} ${key3[0].toUpperCase() + key3.substring(1)}`}
						></textarea>
					</div>
				);
			})}
			<button className="add-button" onClick={(e) => handleAddObject(key1, key2, e)}>Add {name}</button>
		</>
	);
};

export default EditorBlockItems;
