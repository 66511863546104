import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { authActionCreators } from '../../actions';
import { Link } from "react-router-dom";
import Links from "../Links/Links.js";

import logo from '../../assets/zora-logo-white.svg'

import default_icon from '../../assets/avatar.svg'

const Sidebar = props => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const logoutUser = authActionCreators.logoutUser

  const first_name = localStorage.getItem('first_name')
  const last_name = localStorage.getItem('last_name')
  // const email = localStorage.getItem('email')
  const organization = localStorage.getItem('organization')
  var userIcon = default_icon


  const handleLogout = () => {
    localStorage.clear();
    dispatch(logoutUser())
  };


  return (<>
    <div className="sidebar-filler" />
    <div className="sidebar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="sidebar-info">
        <div className="user--image">
          <img src={userIcon} alt="Profile"/>
        </div>
        <div className="user--info">
          <div className="name">{`${first_name} ${last_name}`}</div>
          <div className="organization">{`${organization}`}</div>
        </div>
      </div>
      <Links {...props} open={open} setOpen={setOpen} />
      <div className="bottom-nav">
        <ul>
          <Link to="/" onClick={handleLogout}>
            <li className={`links__link`}>Logout</li>
          </Link>
        </ul>
      </div>
    </div>
  </>);
};

export default Sidebar;
