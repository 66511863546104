import React from 'react';
import { getCurrentDate } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import Editor from './Editor.js'
// import sanitizeHtml from 'sanitize-html';
import { postRequest, putRequest, deleteRequest, getRequest } from '../../utils/';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { setNotification } from '../../actions/notificationActions.js';
import { useDispatch } from 'react-redux';

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setContent = this.setContent.bind(this);
        this.deletePost = this.deletePost.bind(this);
        this.postBlog = this.postBlog.bind(this);
        this.updateBlog = this.updateBlog.bind(this);
    }

    async componentDidMount() {
      let state = this.state;
      let blog = this.props.blog;
      if (this.props.match?.id && !blog){
        let [success, res] = await getRequest(`/blogs/public/${this.props.match.id}`);
        blog = res;
        if (!success)
          state.error = 'Could not find blog post...';
      }

      const first_name = localStorage.getItem('first_name')
      const last_name = localStorage.getItem('last_name')
      const organization = localStorage.getItem('organization');
      let user_categories = localStorage.getItem('blog_categories');
      if (user_categories === 'null') 
        user_categories = null;
      user_categories = user_categories?.split(',');

      state = {
        loading: false,
        id: blog?.id || null,
        author: blog?.author || `${first_name} ${last_name}`,
        title: blog?.title || "",
        date: blog?.date.split('T')[0] || getCurrentDate("-"),
        img: "",
        img_url: "",
        alt_tag: blog?.alt_tag || "",
        meta_content: blog?.meta_content || "",
        user_categories: user_categories || [],
        categories: blog?.categories.split(',') || [],
        content: blog?.content || '',
        organization: blog?.organization || organization,
        text: blog?.text || '',
      }
      this.setState(state);
    }

    async deletePost() {
      let success = (await deleteRequest(`/blogs/${this.state.id}`, this.props.dispatch))[0];
      console.log(success)
      console.log('success????')
      if (success) {
        this.props.dispatch(setNotification('Blog deleted successfully.'));
        this.props.navigate('/dashboard/blog');
      }
      else {
        this.props.dispatch(setNotification('Failed to delete blog post.'));
      }
        
    }

    async postBlog (blog) {
      let [success, message] = await postRequest(`/blogs/`, this.props.dispatch, blog);
      if (success){
        this.props.dispatch(setNotification('Blog posted successfully.'));
        this.props.navigate('/dashboard/blog');
      }
      else
        this.props.dispatch(setNotification(message));
    }
  
    async updateBlog(blog) {
      let [success, message] = await putRequest(`/blogs/${blog.id}`, this.props.dispatch, blog);
      if (success) {
        this.props.dispatch(setNotification('Blog updated successfully.'));
        this.props.navigate('/dashboard/blog');
      }
      else 
        this.props.dispatch(setNotification(message));
    }

    async handleChange(event) {
        if (event.target.name === 'img'){
            let p =  new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result)
                reader.readAsDataURL(event.target.files[0]);
              })
            let res = await p;
            this.setState({...this.state, img_url: event.target.value, [event.target.name]: res});
        }
        else 
            this.setState({ ...this.state, [event.target.name]: event.target.value})
    }

    handleCategoryChange(event) {
      let categories = this.state.categories;
      let ind = categories.indexOf(event.target.id);
      if (ind === -1)
          categories.push(event.target.id);
      else
          categories.splice(ind, 1);
      this.setState({ ...this.state, 'categories': categories,})
    }

    async handleSubmit(event)  {
      event.preventDefault();

      let path = this.state.title.trim().toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").replace(/ /g, '-');
      const updateData = {
          path,
          ...this.state,
          id: this.state.id || uuidv4(),
          categories: this.state.categories.join(','),
      }
      delete updateData.user_categories;
      delete updateData.error;
      delete updateData.loading;

      if (this.state.id)
        this.updateBlog(updateData);
      else
        this.postBlog(updateData);
  }

  setContent(c, _, __, editor) {
    this.setState({...this.state, content: c, text: editor.getText()});
  }

    render() {
      console.log(this.state.user_categories);
      if(this.state.loading){
        return 'Loading...'
      }
      else if (this.state.error) {
        return 'Failed to load blog. Please contact Zora Digital for support.'
      }
      return (
        <div className="blog">
            <div className="blog-form">
                {this.props.match?.id ? 
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <h2 style={{marginRight: 'auto', marginTop: 0}}>Edit Blog Post</h2>
                    <button className='red' onClick={this.deletePost} style={{height: 30, margin: 0, marginRight: 10}}>Delete Post</button>
                    <button className='gray' style={{height: 30, margin: 0}}><a href='/dashboard/blog' >Discard Changes</a></button>
                  </div>
                  : <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <h2 style={{marginRight: 'auto', marginTop: 0}}>Add a new blog post:</h2>
                    <button className='gray' style={{height: 30}}><a href='/dashboard/blog'>Cancel</a></button>
                  </div>
                }
                <form onSubmit={this.handleSubmit}>
                    <div className="half-width">
                        <div>
                          <label for='author'>Author: </label>
                          <input type="text" id="author" name="author" value={this.state.author} placeholder={"Your Name"} onChange={this.handleChange} />
                        </div>
                        <div>
                          <label for='date'>Date: </label>
                          <input type="date" id="date" name="date" value={this.state.date} onChange={this.handleChange} />
                        </div>
                    </div>       
                    <label for='title'>Title: </label>        
                    <input type="text" id="title" name="title" value={this.state.title} placeholder={"Blog Title"} onChange={this.handleChange} />
                    {(this.state.user_categories && this.state.user_categories.length) ? <>
                      <label>Categories (optional): </label>
                      <div style={{display: 'flex'}}>
                        {this.state.user_categories.map(category => <>
                            <span className='checkbox' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: 20}}>
                            <input type='checkbox' checked={this.state.categories.indexOf(category) !== -1} id={category} name={category} onChange={this.handleCategoryChange}/>
                            <label for={category}>{category}</label>
                            </span>
                        </>)}
                      </div>
                    </> : null}
                    <div className='half-width' style={{marginTop: 20, marginBottom: 20}}>
                      <div>
                        <input style={{display: 'none', width: 0}} type="file" id="img" name="img" accept=".png,.jpg" placeholder={"Image"} onChange={this.handleChange} />
                        <label for='alt_tag'>Image Alt Tag (optional):</label>
                        <input type="text" id="alt_tag" name="alt_tag" value={this.state.alt_tag} placeholder={"Image Alt Tag"} style={{paddingLeft: 10}} onChange={this.handleChange} />
                        <input type="button" id='img_input' value={'Upload Blog Image'} placeholder={"Image"} onClick={() => document.getElementById('img').click()} />
                      </div>
                      {(this.state.img || this.state.id) && 
                        <div style={{marginRight: 'auto', marginLeft: 50}}>
                          <img alt='blog thumbnail' style={{width: 100, height: 100, display: 'block', }} src={this.state.img ? this.state.img : `https://zora-blog.s3.us-east-2.amazonaws.com/${this.state.organization}/${this.state.id}.png`} />
                          <label style={{marginTop: 10, display: 'inline-block', maxWidth: 300, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{this.state.img_url.split('\\').slice(-1)}</label>
                        </div>
                      }
                    </div>
                    <label>Blog Post:</label>
                    <div className="editor">
                        {<Editor content={this.state.content} setContent={this.setContent} />}
                    </div>
                    <label>Meta Description (optional):</label>
                    <input type="text" id="meta_content" name="meta_content" value={this.state.meta_content} placeholder={"Meta Description"} onChange={this.handleChange} />
        
                    <div className="submit" type='submit'>
                        <button type="submit" name="Login">Submit</button>
                    </div>
                </form>
            </div>
        </div>)
    }
}

const BlogComponent = (props) => {
  let location = useLocation();
  let params = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  return <Blog {...props} dispatch={dispatch} match={params} blog={location.state?.blog} navigate={navigate}/>
}

export default BlogComponent;
